import React from "react";
import GridComponent from "../SuperClasses/GridComponent";
import {
    Box,
    CircularProgress,
    Paper,
    Stack,
} from "@mui/material";
import { GET_GS, DEFAULT_FETCH_HEADERS } from "../config";
import { Page } from 'react-pdf/dist/esm/entry.webpack5'
import { PDFWidget } from "./PDFWidget";
import {GSDocumentChatWidget} from "./GSDocumentChatWidget";
import GSOrgCard from "./GSOrgCard.js"
import GSStakeholderCard from "./GSStakeholderCard.js"
import Search from "./Search";
import HTMLWidget from "./HTMLWidget.js"

export default class GSDocumentWidget extends GridComponent {
    constructor(props) {
        super(props);
        this.initComponent({ "type": "GSWidget", "title": "Document" })
        this.mdbExpanded = false;
    }

    componentDidMount() {
        this.setState({ "currentTab": 0 })
        if (!this.promise) {
            const requestOptions = {
                method: 'POST',
                headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ "_id": this.props["id"] })
            };
            this.promise = fetch(GET_GS, { ...requestOptions })
                .then((response) => response.json())
                .then((data) => this.setState({ documentData: data }))
                .catch((error) => this.setState({ error: error }));
        }
    }

    handleTagClick = (event) => {
        event.preventDefault();
        const tagText = event.target.textContent;
        const searchComponent = this.props.parentGrid.state.items.find(item => item.component === Search);
        if (searchComponent && searchComponent.ref.current) {
            searchComponent.ref.current.addKeyword(tagText);
        }
    }

    handleLocationTagClick = (event) => {
        event.preventDefault();
        const locationText = event.target.textContent;
        const searchComponent = this.props.parentGrid.state.items.find(item => item.component === Search);
        if (searchComponent && searchComponent.ref.current) {
            searchComponent.ref.current.addLocation(locationText);
        }
    }

    onOpenHTMLClick = (event) => {
                this.state.parentGrid.createNewComponent({ 
                    w: 10, 
                    h: 7, 
                    component: HTMLWidget, 
                    componentArgs: { "html": this.state.documentData.doc.fields['field_full_text:value'][0] } 
                });
    }

    onOpenPDFClick = async (event) => {
        try {
            const pdf_url = this.state.documentData.doc.fields['field_url:url'][0];
            const response = await fetch(`https://europe-west3-inq-app-402508.cloudfunctions.net/get_url?url=${encodeURIComponent(pdf_url)}`);
            if (!response.ok) throw new Error('Failed to fetch PDF data');
            const data = await response.json();
            if (data && data.pdf) {
                await new Promise(resolve => this.setState({ 
                    pdfData: data.pdf
                }, resolve));
                this.state.parentGrid.createNewComponent({ 
                    w: 7, 
                    h: 12, 
                    component: PDFWidget, 
                    componentArgs: { "pdf": this.state.pdfData } 
                });
            } else {
                throw new Error('PDF data is missing or invalid');
            }
        } catch (error) {
            console.error('Error in onOpenPDFClick:', error);
            // You might want to show an error message to the user here
        }
    }

    onPDFLoadSuccess = ({ numPages }) => {
        this.setState({ "totalPages": numPages, "currentPage": 1 })
    }

    renderPDFPages = () => {
        let pages = []
        for (let i = 1; i <= this.state.totalPages; i++) {
            pages.push(<Page pageNumber={i} />)
        }
        return pages;
    }

    onPageChange = (event, value) => {
        this.setState({ "currentPage": value })
    }

    onTabChange = (event, value) => {
        this.setState({ "currentTab": value })
    }

    onChatBtnClick = (event) => {
        this.state.parentGrid.createNewComponent({ w: 12, h: 8, component: GSDocumentChatWidget, componentArgs: { documents: [{ id: this.props["id"], type: "European Parliament", title: this.state.documentData.title }] } })
    }

    handleDivClick = (event) => {
        const target = event.target;
        if (event.target !== event.currentTarget) {
            this.state.parentGrid.createNewComponent({w:3,h:6, component:GSOrgCard,  componentArgs:{"id":target.dataset.id}})
        }
    }

    handleSDivClick = (event) => {
        const target = event.target;
        if (event.target !== event.currentTarget) {
            this.state.parentGrid.createNewComponent({w:3,h:6, component:GSStakeholderCard,  componentArgs:{"id":target.dataset.id}})
        }
    }

    renderChat() {}

    render() {
        if (this.state.documentData === undefined || this.state.documentData.doc === undefined) {
            return (
                <Paper className={"card"} elevation={0} >
                    <Stack height={"100%"} overflow={"hidden"}>
                        {this.renderTopBar()}
                        <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>
                    </Stack>
                </Paper>)
        } else {
            const documentUrl = this.state.documentData.doc.fields['field_url:url']?.[0] || null;
            const documentHTML = this.state.documentData.doc.fields['field_full_text:value']?.[0] || null;
	    let isPDF = false;

	    if(documentUrl) {
	            isPDF = documentUrl.toLowerCase().endsWith('.pdf');
            }

            return (
                <Paper elevation={0} className={"card"} >
                    {this.renderTopBar()}
                    <div className="document-title">
                        <h1 className="document-title__title">{this.state.documentData.doc.fields.title[0]}</h1>
                        <div className="document-title__subtitle">{this.state.documentData.doc.fields['field_provider:title']}</div>
                    </div>
                    <div className="card__scroll-area">
                        <div className="card-section">
                            <p>
                                <small
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.documentData.doc.renderedkw.replace(
                                            /<a\s+href="#"\s+value="\d+">([^<]+)<\/a>/g,
                                            '<span class="link">$1</span>'
                                        )
                                    }}
                                    onClick={this.handleTagClick}
                                ></small>
                            </p>
                            <p>{this.state.documentData.doc.fields['field_summary_repeater:value']?.[0] ? this.state.documentData.doc.fields['field_summary_repeater:value'][0] : ''}</p>
                        </div>
                        <div className="card-section">
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Information</h3>
                                </div>
                            </div>
                            <div className="card-section__body">
                                <div className="meta">
                                    <div className="meta__title">Date</div>
                                    <div className="meta__data">{new Date(parseInt(this.state.documentData.doc.fields.field_date_retrieved, 10) * 1000).toISOString().split('T')[0]}</div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Location(s):</div>
                                    <div 
                                        className="meta__data" 
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.documentData.doc.renderedlo.replace(
                                                /<a\s+href="#"\s+value="\d+">([^<]+)<\/a>/g,
                                                '<span class="link">$1</span>'
                                            )
                                        }}
                                        onClick={this.handleLocationTagClick}
                                    ></div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Stakeholder(s):</div>
                                    <div className="meta__data" onClick={this.handleSDivClick} dangerouslySetInnerHTML={{__html: this.state.documentData.doc.renderedsh}}></div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Organisation(s):</div>
                                    <div className="meta__data" onClick={this.handleDivClick} dangerouslySetInnerHTML={{__html: this.state.documentData.doc.renderedor}}></div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Original document:</div>
		                    {isPDF ? (
                                        <div className="button" onClick={this.onOpenPDFClick}>Open Document</div>
                                    ) : documentUrl ? (
                                        <a href={documentUrl} target="_blank" rel="noopener noreferrer">Open original</a>
                                    ) : documentHTML ? (
                                        <div className="button" onClick={this.onOpenHTMLClick}>Open Entire Page</div>
			  	    ) : null
				    }
                                </div>
                            </div>
                        </div>
                        <div className="card-section">
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Analyze</h3>
                                </div>
                                <div>
                                    <div className="button" onClick={this.onChatBtnClick}>Chat with AI</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            )
        }
    }
}
