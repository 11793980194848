import React, { Component, useCallback, Fragment } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import {
    Alert,
    Box,
    Button, Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel, Link,
    Paper,
    Snackbar,
    Stack,
    TextField,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
} from "@mui/material";
import { loadFull } from "tsparticles";
// import Particles from "react-tsparticles";
import { resetPassword, signInWithGoogle, signInWithMS, signInWithUser } from "../../utils/firebase";
import '../../../src/App.css';
// import { value } from "lodash/seq";
// import { sendPasswordResetEmail } from "firebase/auth";
// import MenuIcon from '@mui/icons-material/Menu';
// import Logo from './inq_logo_blue.png';



export default class LoginWidget extends Component {
    constructor(props) {
        super(props);
        this.state = { particlesLoaded: false, loginFailed: undefined, passwordReset: false }
        this.ppConsentGiven = false;
        this.cConsentGiven = Cookies.get("CookieConsent");
        this.loginFailedReason = "Login failed";
        this.username = "";
        this.password = "";
    }

    async initParticles(engine) {

        await loadFull(engine);
    }

    // particlesLoaded = () => {
    //     this.setState((prev) => ({ ...prev, particlesLoaded: true }))
    // }

    handleGoogleLogin = () => {
        if (this.ppConsentGiven && this.cConsentGiven)

            signInWithGoogle()
        else {
            this.handleConsentNotGiven()
        }
    }

    handleMSLogin = () => {
        if (this.ppConsentGiven && this.cConsentGiven)
            signInWithMS()
        else {
            this.handleConsentNotGiven()
        }
    }

    handleForgotPassword = async () => {
        if (this.username.length === 0) {
            this.loginFailedReason = "Please fill out the E-Mail field first to reset your password."
            this.setState((prev) => ({ ...prev, loginFailed: true }))
        } else {
            let result = await resetPassword(this.username)
            if (!result) {
                this.loginFailedReason = "User does not exist.";
                this.setState((prev) => ({ ...prev, loginFailed: true }))
            }
            else {
                this.setState((prev) => ({ ...prev, passwordReset: true }))
            }

        }
    }

    handleConsentNotGiven = () => {
        if (!this.cConsentGiven)
            this.loginFailedReason = "We rely on cookies to authenticate you. Since you chose to decline them, we can not process your request.";
        else if (!this.ppConsentGiven)
            this.loginFailedReason = "You need to accept the Privacy Policy to continue.";
        this.setState((prev) => ({ ...prev, loginFailed: true }))
    }

    renderCookieBanner = () => {
        if (this.cConsentGiven === undefined) {
            return (<CookieConsent
                location="bottom"
                enableDeclineButton={true}
                buttonText="I accept"
                declineButtonText="I refuse"
                cookieName="CookieConsent"
                style={{ background: "#ededed", color: "#3f4e55" }}
                buttonStyle={{
                    background: "#86cee1",
                    color: "#3f4e55",
                    fontSize: "13px",
                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    padding: "1em 2em",
                    textTransform: "uppercase",
                }}
                declineButtonStyle={{
                    background: "#ededed",
                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                    borderRadius: "5px",
                    color: "#3f4e55",
                    fontSize: "14px",
                    padding: "1em 2em",
                    textTransform: "uppercase",
                }}
                onAccept={() => (this.cConsentGiven = true)}
                onDecline={() => (this.cConsentGiven = false)}
                expires={null}
            >
                This website uses cookies to enhance the user experience. Fur further information, please visit our {" "}
                <a href="https://www.iubenda.com/privacy-policy/68089143">Privacy Policy</a>{" "}
                and/or our {" "}
                <a href="https://dashboard.perspectives.tools/cookies">Cookie Policy</a>.
            </CookieConsent>)
        }
        else {
            return;
        }
    }

    renderAppBar() {
        return (
            <AppBar position="static" sx={{ bgcolor: 'black' }}>
                <Toolbar>
                    {/* <img src={Logo} alt="Company Logo" style={{ height: '30px' }} /> */}
                    <img src="/inq_Logo_gray.png" loading="lazy" alt="" className="navbar__logo" />
                </Toolbar>
            </AppBar>
        );
    }

    render() {
        return (
            <Fragment>
                {this.renderAppBar()}
                {this.renderBody()}
                {/* <Particles
                    className={"particles"}
                    id="particles"
                    init={this.initParticles}
                    loaded={this.particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: "#18548c",
                            },
                        },
                        fpsLimit: 120,
                        interactivity: {
                            events: {
                                onHover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#ffffff",
                            },
                            links: {
                                color: "#ffffff",
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1,
                            },
                            collisions: {
                                enable: true,
                            },
                            move: {
                                directions: "none",
                                enable: true,
                                outModes: {
                                    default: "bounce",
                                },
                                random: true,
                                speed: 3,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 800,
                                },
                                value: 80,
                            },
                            opacity: {
                                value: 0.5,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                value: { min: 1, max: 5 },
                            },
                        },
                        detectRetina: true,
                    }}
                /> */}
                {this.renderCookieBanner()}
            </Fragment>
        )
    }

    renderBody() {
        // if (this.state.particlesLoaded) {
        return (
            <Box display={"flex"} height={"100vh"} alignItems={"center"} justifyContent={"center"} sx={{ bgcolor: "#FFF", border: "none" }}>
                <Snackbar open={this.state.loginFailed === true}
                    onClose={() => this.setState((prev) => ({ ...prev, loginFailed: false }))}>
                    <Alert severity={"error"}>{this.loginFailedReason}</Alert>
                </Snackbar>
                <Snackbar open={this.state.passwordReset === true}
                    onClose={() => this.setState((prev) => ({ ...prev, passwordReset: false }))}>
                    <Alert severity={"success"}>{"Successfully sent password reset E-Mail."}</Alert>
                </Snackbar>
                <Paper elevation={0} sx={{ minWidth: "30%", minHeight: "50%", zIndex: 1, boxShadow: 'none' }} overflow={"auto"}>
                    <Stack m={2} spacing={1} height={"100%"} justifyContent={"flex-start"} overflow={"auto"}>
                        <Typography
                            alignSelf={"center"}
                            // className="login_title"
                            sx={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 700,
                                color: '#000',
                                textAlign: 'center',
                                fontSize: '64px',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                            }}

                        >Login</Typography>
                        <Typography
                            alignSelf={"center"}
                            // variant={"h6"}
                            sx={{
                                height: 38,
                                color: '#000',
                                textAlign: 'center',
                                fontFamily: "'Montserrat', sans-serif",
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                                marginBottom: '12px' // Añade un margen en la parte inferior

                            }}
                        >Welcome to the inQ ecosystem</Typography>

                        <form onSubmit={(e) => {
                            e.preventDefault()
                            if (this.ppConsentGiven && this.cConsentGiven) {
                                let result = (async () => {
                                    let success = await signInWithUser(this.username, this.password)
                                    if (!success) {
                                        this.loginFailedReason = "Wrong credentials";
                                        this.setState((prev) => ({ ...prev, loginFailed: true }))
                                    }
                                })().then((r) => {


                                })

                            }
                            else {
                                this.handleConsentNotGiven()
                            }
                        }}>
                            <Stack spacing={1}>
                                <TextField autoComplete={""} label={"email"}
                                    onChange={(event) => (this.username = event.target.value)} className="login_textfield"></TextField>
                                <TextField autoComplete={""} label={"password"}
                                    onChange={(event) => (this.password = event.target.value)}
                                    type={"password"} className="login_textfield"></TextField>
                                <Button type={"submit"} className="loginButton">login</Button>
                                {/* <Button variant={"outlined"} onClick={this.handleForgotPassword}>FORGOT PASSWORD</Button> */}
                            </Stack>
                        </form>
                        {/* <Divider>OR</Divider>
                            <Button variant="contained" disabled={true} color={"googleRed"}>LOGIN WITH
                                GOOGLE</Button>
                            <Button variant="contained" color={"msBlue"} onClick={this.handleMSLogin}>LOGIN WITH MICROSOFT</Button> */}

                        <FormControlLabel style={{ alignSelf: "center" }}
                            control={<Checkbox onChange={(event) => (this.ppConsentGiven = event.target.checked)} sx={{
                                color: 'transparent',
                                '&.MuiCheckbox-root': {
                                    position: 'relative',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '16px',
                                        height: '16px',
                                        backgroundColor: '#D9D9D9',
                                        borderRadius: '2px',
                                        zIndex: -1,
                                        border: 'none'
                                    },
                                },
                                // '&.Mui-checked': {
                                //     color: 'green', // Color del ícono de verificación cuando está marcado
                                //     '&::before': {
                                //         backgroundColor: 'green', // Color de fondo cuando está marcado
                                //     },
                                // },
                            }} />}
                            label={<Typography sx={{
                                color: '#000',
                                textAlign: 'center',
                                fontFamily: "'Montserrat', sans-serif",
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal'
                            }}>I accept the <Link href={"https://www.iubenda.com/privacy-policy/68089143"}>terms of use.</Link>
                                {/* and <Link href={"https://dashboard.perspectives.tools/cookies"}>cookie policy</Link> 
                                     of this service  */}
                            </Typography>} />
                    </Stack>
                </Paper>
            </Box>


        )
    }
    // else {
    //     return (<Box display={"flex"} height={"100vh"} alignItems={"center"}
    //         justifyContent={"center"}><CircularProgress /></Box>)
    // }
    // }
}
