import React from 'react';
import { Paper, Box } from '@mui/material';
import GridComponent from "../SuperClasses/GridComponent";

export class HTMLWidget extends GridComponent {
    constructor(props) {
        super(props);
        this.pdfWrapper = React.createRef();
        this.initComponent({"type": "HTMLWidget", "title": "HTML Document"});
    }

    getDivWidth = () => {
        return this.pdfWrapper.current != null ? this.pdfWrapper.current.getBoundingClientRect().width : "600";
    }

    createMarkup = () => {
        return {__html: this.props.html};
    }

    renderHTML = () => {
        return (
            <Box flex={1} overflow="auto">
                <div id="htmlWrapper" style={{width: "100%"}} ref={this.pdfWrapper}>
                    <div dangerouslySetInnerHTML={this.createMarkup()} />
                </div>
            </Box>
        );
    }

    render() {
        return (
            <Paper className="card">
                {this.renderTopBar()}
                <div className="card__body">
                    <div className="card__body-inner">
                        {this.renderHTML()}
                    </div>
                </div>
            </Paper>
        );
    }
}

export default HTMLWidget;
