import React from "react";
import GridComponent from "../SuperClasses/GridComponent";
import {
    Box,
    CircularProgress,
    Paper,
    Tooltip,
} from "@mui/material";
import { FETCH_STAKEHOLDER, DEFAULT_FETCH_HEADERS } from "../config";
import GSOrgCard from "./GSOrgCard.js"
import Search from "./Search";
import Sparkline from "../InCardWidgets/Sparkline";

export default class GSStakeholderCard extends GridComponent {
    constructor(props) {
        super(props);
        this.initComponent({ "type": "Stakeholder", "title": "Stakeholder Profile" })
    }

    componentDidMount() {
        if (!this.promise) {
            const requestOptions = {
                method: 'POST',
                headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ "oid": this.props["id"] })
            };

            this.promise = fetch(FETCH_STAKEHOLDER, { ...requestOptions })
                .then((response) => response.json())
                .then((data) => this.setState({ stakeholderData: data }))
                .catch((error) => this.setState({ error: error }));
        }
    }

    handleTagClick = (event) => {
        event.preventDefault();
        const tagText = event.target.textContent;
        const searchComponent = this.props.parentGrid.state.items.find(item => item.component === Search);
        if (searchComponent && searchComponent.ref.current) {
            searchComponent.ref.current.addKeyword(tagText);
        }
    }

    handleLocationTagClick = (event) => {
        event.preventDefault();
        const locationText = event.target.textContent;
        const searchComponent = this.props.parentGrid.state.items.find(item => item.component === Search);
        if (searchComponent && searchComponent.ref.current) {
            searchComponent.ref.current.addLocation(locationText);
        }
    }

    handleDivClick = (event) => {
        const target = event.target;
        if (event.target !== event.currentTarget) {
            this.state.parentGrid.createNewComponent({w:3,h:6, component:GSOrgCard,  componentArgs:{"id":target.dataset.id}})
        }
    }

	renderChannelActivity = () => {
    if (!this.state.stakeholderData.activity_data) return null;

    const activityData = Object.entries(this.state.stakeholderData.activity_data)
        .map(([timestamp, value]) => ({
            date: new Date(parseInt(timestamp) / 1000), // Convert to seconds
            value
        }))
        .sort((a, b) => a.date - b.date);

    const dates = activityData.map(item => item.date.toISOString().split('T')[0]);
    const docs = activityData.map(item => item.value);

    return (
        <div className="channel-activity">
            <div>
                <Sparkline values={docs} categories={dates} seriesName={"Docs published:"} />
            </div>
        </div>
    );
}

    render() {
        if (this.state.stakeholderData === undefined && this.state.error === undefined) {
            return (
                <div className={"card"} sx={{ width: "100%", height: "100%" }} style={{ overflow: "hidden", userSelect: "none" }}>
                    {this.renderTopBar()}
                    <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>
                </div>)
        } else {
            return (
                <Paper elevation={0} className="card">
                    {this.renderTopBar()}
                    <div className="stakeholder">
                        <div className="stakeholder__bg"></div>

                        <div className="stakeholder-profile">
                            <Tooltip title={<><p>Stakeholder photo</p></>} placement={"right"}>
                                <div className="stakeholder-picture">
                                    <img src={this.state.stakeholderData.doc.field_image_link.und && this.state.stakeholderData.doc.field_image_link.und[0] && this.state.stakeholderData.doc.field_image_link.und[0].original_url ? this.state.stakeholderData.doc.field_image_link.und[0].original_url : '/Unknown_person.jpg'} loading="lazy" alt="" className="stakeholder-img" />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="stakeholder__title">{this.state.stakeholderData.doc.name}</div>
		        {this.state.stakeholderData.doc.field_position?.und?.[0]?.value && (
	                        <div className="stakeholder__subtitle">{this.state.stakeholderData.doc.field_position.und[0].value}
				</div>
			)}
                    </div>
                    <div className="card__scroll-area">
                        <div className="card-section">
                            <p>
                                <small
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.stakeholderData.doc.renderedkw.replace(
                                            /<a\s+href="#"\s+value="\d+">([^<]+)<\/a>/g,
                                            '<span class="link">$1</span>'
                                        )
                                    }}
                                    onClick={this.handleTagClick}
                                ></small>
                            </p>
		            {this.state.stakeholderData.doc.body?.und?.[0]?.value && (
				    <p>{this.state.stakeholderData.doc.body.und[0].value}</p>
			    )}
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Metadata</h3>
                                </div>
                            </div>
                            <div className="card-section__body">
		    		{this.state.stakeholderData.doc.field_birthday?.und?.[0]?.value && (
					<div className="meta">
					    <div className="meta__title">Birthday</div>
					    <div className="meta__data">{this.state.stakeholderData.doc.field_birthday.und[0].value}</div>
					</div>
				)}
                                <div className="meta">
                                    <div className="meta__title">Location(s):</div>
                                    <div 
                                        className="meta__data" 
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.stakeholderData.doc.renderedlo.replace(
                                                /<a\s+href="#"\s+value="\d+">([^<]+)<\/a>/g,
                                                '<span class="link">$1</span>'
                                            )
                                        }}
                                        onClick={this.handleLocationTagClick}
                                    ></div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Cohort(s):</div>
                                    <div className="meta__data" onClick={this.handleDivClick} dangerouslySetInnerHTML={{__html: this.state.stakeholderData.doc.renderedor}}></div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Organisation(s):</div>
                                    <div className="meta__data link org" onClick={this.handleDivClick} dangerouslySetInnerHTML={{__html: this.state.stakeholderData.doc.renderedora}}></div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Website:</div>
                                    <a href={this.state.stakeholderData.doc.field_website?.[0]?.value ? this.state.stakeholderData.doc.field_website[0].value : ''} target="_blank" rel="noopener noreferrer" download>{this.state.stakeholderData.doc.field_website?.[0]?.value ? this.state.stakeholderData.doc.field_website[0].value : ''}</a>
                                    <a href={this.state.stakeholderData.doc.field_website?.und?.[0]?.value ? this.state.stakeholderData.doc.field_website.und[0].value : ''} target="_blank" rel="noopener noreferrer" download>{this.state.stakeholderData.doc.field_website?.und?.[0]?.value ? this.state.stakeholderData.doc.field_website.und[0].value : ''}</a>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Contacts:</div>
                                    <a href={this.state.stakeholderData.doc.field_email?.und?.[0]?.url ? "mailto:" + this.state.stakeholderData.doc.field_email.und[0].url : ''} target="_blank" rel="noopener noreferrer" download>{this.state.stakeholderData.doc.field_email?.und?.[0]?.url ? 'E-mail' : ''}</a> 
                                    <a href={this.state.stakeholderData.doc.field_twitter?.[0]?.value ? "https://x.com/" + this.state.stakeholderData.doc.field_twitter[0].value : ''} target="_blank" rel="noopener noreferrer" download>{this.state.stakeholderData.doc.field_twitter?.[0]?.value ? 'Twitter' : ''}</a> 
                                    <a href={this.state.stakeholderData.doc.field_twitter?.und?.[0]?.value ? "https://x.com/" + this.state.stakeholderData.doc.field_twitter.und[0].value : ''} target="_blank" rel="noopener noreferrer" download>{this.state.stakeholderData.doc.field_twitter?.und?.[0]?.value ? 'Twitter' : ''}</a> 
                                    <a href={this.state.stakeholderData.doc.field_linkedin?.und?.[0]?.value ? "https://linkedin.com/" + this.state.stakeholderData.doc.field_linkedin.und[0].value : ''} target="_blank" rel="noopener noreferrer" download>{this.state.stakeholderData.doc.field_linkedin?.und?.[0]?.value ? 'Linkedin' : ''}</a>
                                    <a href={this.state.stakeholderData.doc.field_linkedin?.[0]?.value ? "https://linkedin.com/" + this.state.stakeholderData.doc.field_linkedin[0].value : ''} target="_blank" rel="noopener noreferrer" download>{this.state.stakeholderData.doc.field_linkedin?.[0]?.value ? 'Linkedin' : ''}</a>
                                </div>
                            </div>
                        </div>
                        <div className="card-section">
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Channel Activity</h3>
                                </div>
                            </div>
                                <div className="meta">
                                    <div className="meta__title">Total Documents:</div>
                                    <div className="meta__data">{this.state.stakeholderData.total_documents}</div>
                                </div>
                            <div className="card-section__body">
                                {this.renderChannelActivity()}
                            </div>
                        </div>
                    </div>
                </Paper>
            );
        }
    }
}
