import GridComponent from "../SuperClasses/GridComponent";
import {
    Alert,
    Box, Button, Chip,
    CircularProgress,
    //Divider,
    IconButton,
    Paper, Snackbar,
    Stack, //Tab, Tabs,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SearchResultCharts from "../InCardWidgets/SearchResultCharts";
import _ from "lodash";
import PDataGrid from "../InCardWidgets/PDataGrid";
import { RELENG, SEARCH_NEWS, SEARCH_DOCUMENTS, DEFAULT_FETCH_HEADERS, RELENG_STAKEHOLDERS, RELENG_ORGANISATIONS } from "../config";
import { DataGrid } from "@mui/x-data-grid";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import NewsWidget from "./NewsWidget";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {GSDocumentChatWidget} from "./GSDocumentChatWidget";
import Networker from "./NetworkerCard.js";
import PolylineIcon from '@mui/icons-material/Polyline';

export const getColumnWidth = (data, accessor, headerText) => {
    const cellLength = Math.max(
        ...data.map(row => {
            let value = '';

            if (typeof accessor === 'string') {
                value = _.get(row, accessor);
            } else {
                value = accessor(row);
            }

            if (typeof value === 'number') return value.toString().length;
            return (value || '').length;
        }),
        headerText.length
    );

    const magicSpacing = 12;
    return cellLength * magicSpacing;
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh', // Use full viewport height for the container
  },
  summaryHtmlContainer: {
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 1,  // Allow shrinking but don't force growth
    maxHeight: '35vh', // Max 35% of the viewport height
    overflowY: 'auto', // Enable scrolling if content exceeds max height
    marginBottom: '8px', // Optional space between summary and mainContent
  },
  mainContentContainer: {
    flexGrow: 1,
    overflowY: 'auto', // Allow scrolling within the remaining space
  },
};

export default class SearchResultGrid extends GridComponent {
    constructor(props) {
        super(props);
        this.state = { ...props, showFilters: false, selectedDocuments: [], initial: true, summaryHtml: null, isSummaryLoading: false }
        this.initComponent({ "type": "ResultGrid", "title": "Search Results:'" + props.query + "'" })
    }

	componentDidUpdate(prevProps, prevState) {
		// Check if docsList was just populated and context exists
		if (
			this.state.docsList &&
			this.state.context &&
			(!prevState.docsList || !prevState.context ||
				prevState.docsList !== this.state.docsList ||
				prevState.context !== this.state.context)
		) {
			this.fetchSummaryData();
		}
		if (prevState.summaryHtml !== this.state.summaryHtml) {
			console.log('Updated summaryHtml in componentDidUpdate:', this.state.summaryHtml);
		}
	}

    async fetchSummaryData() {
        try {
            if (this.state.docsList && this.state.context) {
		this.setState({ isSummaryLoading: true });
                const formData = new FormData();
                formData.append('news_items', JSON.stringify(this.state.docsList));
                formData.append('context', this.state.context);

                const llmResponse = await fetch(`https://jotup.co/api/llm?api_key=ZiS9csmECPOKqD9a`, {
                    method: 'POST',
                    body: formData
                });
                const llmData = await llmResponse.json();
                const summaryHtml = llmData.choices[0].message.content;
                this.setState({ summaryHtml, isSummaryLoading: false });
		console.log(this.state.summaryHtml);
            }
        } catch (error) {
            console.error('Error fetching summary:', error);
            this.setState({ summaryError: error, isSummaryLoading: false });
        }
    }

	componentDidMount() {
		if (this.isFetching) return;
		this.isFetching = true;

		//if (!this.promise) {
		if (!this.results && !this.newsResults && !this.relengResults) {

			this.setState({tabsWithDataCount: [this.state.filters.monitoredSources.enabled, (this.state.filters.bundestag.enabled || this.state.filters.ep.enabled || this.state.filters.books.enabled || this.state.filters.twitter.enabled), this.state.filters.news.enabled, this.state.filters.orgs.enabled, this.state.filters.stakeholders.enabled].filter(Boolean).length});

			const fetchPromises = [];
			if (this.state.filters.news.enabled) {
				const newsRequestOptions = {
					method: 'POST',
					headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
					body: JSON.stringify(
						{
							search_term: this.state.query,
							search_periode: this.state.filters.news.period,
							max_articles: this.state.filters.news.maxArticles
						})
				};
				fetchPromises.push(fetch(SEARCH_NEWS, { ...newsRequestOptions })
					.then((response) => response.json())
					.then(data => ({ type: 'news', data }))
					.catch(error => ({ type: 'news', error })));
			}
			if ((this.state.filters.bundestag.enabled || this.state.filters.ep.enabled || this.state.filters.books.enabled || this.state.filters.twitter.enabled) && !this.state.results) {
				const requestOptions = {
					method: 'POST',
					headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
					body: JSON.stringify({ keyword: this.state.query, filters: this.state.filters })
				};
				fetchPromises.push(fetch(SEARCH_DOCUMENTS, { ...requestOptions })
					.then((response) => response.json())
					.then(data => ({ type: 'documents', data }))
					.catch(error => ({ type: 'documents', error })));
			}    

			if (this.state.filters.monitoredSources.enabled && !this.state.relengResults) {
				const RSrequestOptions = {
					method: 'POST',
					headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
					body: JSON.stringify({ keyword: this.state.query, locations: this.state.locations, language: this.state.language, filters: this.state.filters, organisations: this.state.organisations, stakeholders: this.state.stakeholders })
				};
				fetchPromises.push(fetch(RELENG, { ...RSrequestOptions })
					.then((response) => response.json())
					.then(data => ({ type: 'releng', data }))
					.catch(error => ({ type: 'releng', error })));
			}
			if (this.state.filters.stakeholders.enabled && !this.state.stakeResults) {
				const SrequestOptions = {
					method: 'POST',
					headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
					body: JSON.stringify({ keyword: this.state.query, locations: this.state.locations, language: this.state.language, filters: this.state.filters, organisations: this.state.organisations, stakeholders: this.state.stakeholders })
				};
				fetchPromises.push(fetch(RELENG_STAKEHOLDERS, { ...SrequestOptions })
					.then((response) => response.json())
					.then(data => ({ type: 'stakeholders', data }))
					.catch(error => ({ type: 'stakeholders', error })));
			}
			if (this.state.filters.orgs.enabled && !this.state.orgResults) {
				const OrequestOptions = {
					method: 'POST',
					headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
					body: JSON.stringify({ keyword: this.state.query, locations: this.state.locations, language: this.state.language, filters: this.state.filters, organisations: this.state.organisations, stakeholders: this.state.stakeholders })
				};
				fetchPromises.push(fetch(RELENG_ORGANISATIONS, { ...OrequestOptions })
					.then((response) => response.json())
					.then(data => ({ type: 'organisations', data }))
					.catch(error => ({ type: 'organisations', error })));
			}
			Promise.allSettled(fetchPromises).then(results => {
				results.forEach(result => {
					if (result.status === 'fulfilled') {
						switch (result.value.type) {
							case 'news':
								this.setState({ newsResults: result.value.data, showNewsFeedback: true });
								break;
							case 'documents':
								this.setState({ results: result.value.data["objects"], chartData: result.value.data["graph_data"] });
								break;
							case 'releng':
								this.setState({ relengResults: result.value.data["objects"], chartData: result.value.data["graph_data"], docsList: result.value.data["docs_list"] });
								break;
							case 'stakeholders':
								this.setState({ stakeResults: result.value.data["objects"], stakeChartData: ""});
								break;
							case 'organisations':
								this.setState({ orgResults: result.value.data["objects"], orgChartData: ""});
								break;
							default:
								break;
						}
					} else {
						console.error(result.reason);
					}
				});
				this.isFetching = false;
			});
		}
	}

    handleAddBtnClick = (id, title) => {
      this.setState((prev) => ({
        ...prev,
        selectedDocuments: [...prev.selectedDocuments, { id: id, title: title }],
      }));
    };


    handleDocumentDelete = (id) => {
      this.setState((prev) => ({
        ...prev,
        selectedDocuments: prev.selectedDocuments.filter((el) => el.id !== id),
      }));
    };

    onChatBtnClick = () => {
      this.state.parentGrid.createNewComponent({ w: 12, h: 8, component: GSDocumentChatWidget, componentArgs: { documents: this.state.selectedDocuments } })
    }

    onNewsCellClick = (event, context) => {
        if (event.field === "Title") {
            this.state.parentGrid.createNewComponent({
                w: 3,
                h: 6,
                component: NewsWidget,
                componentArgs: { "id": event.row["id"] }
            })
        }
    }

    renderNewsTab = () => {
        if (this.state.newsResults === undefined) {
            return (
                <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>)
        } else if (this.state.newsResults.length === 0) {
            return (<Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}>
                <Alert variant="outlined" severity="warning">
                    Sorry, we could not get any results for your query
                </Alert>
            </Box>)
        } else {
	    //ncols = 
            /*let columns = []
            Object.keys(this.state.newsResults[0]).forEach(e => {
                columns.push({
                    field: e,
                    headerClassName: "datagrid_headers",
                    headerName: e,
                    flex: 1,
                    hide: e.toLowerCase().includes("id"),
                    maxWidth: getColumnWidth(this.state.newsResults, e, e),
                })
            })*/
            return (
                <div className={"card__table"}>
                    <DataGrid rows={this.state.newsResults}
                        columns={this.setupColumns(this.state.newsResults)}
                        parent={this}
                        onCellClick={this.onNewsCellClick}
                        getCellClassName={(params) => {
                            if ((params.field !== "Title")) {
                                return '';
                            }
                            return "clickable_datagrid_cells";
                        }} />
                </div>)
        }
    }

    onShowChartsClicked = (event) => {
        if (this.state.results || this.state.relengResults) {
            this.state.parentGrid.createNewComponent({
                w: 5,
                h: 5,
                component: SearchResultCharts,
                componentArgs: { "data": this.state.chartData }
            })
        }
    }

    ShowNetworker = (event) => {
	    var relres = [];
	    var stares = [];
	    var orgres = [];
	    if ((!this.state.results || this.state.results.length === 0) && (!this.state.relengResults || this.state.relengResults.length === 0) && (!this.state.orgResults || this.state.orgResults.length === 0) && (!this.state.stakeResults || this.state.stakeResults.length === 0)) {
	    } else {
	        if( this.state.tabsWithDataCount >= 2) {
			if(typeof this.state.currentTab !== 'undefined') {
				if(this.state.currentTab === 3) { 
					orgres = this.state.orgResults;
				}else if (this.state.currentTab === 4) {
					stares = this.state.stakeResults
				} else if(this.state.currentTab === 0) {
					relres = this.state.relengResults
				}
			}
		} else {
		    if (this.state.relengResults && this.state.relengResults.length > 0) {
			    relres = this.state.relengResults;
		    } else if (this.state.stakeResults && this.state.stakeResults.length > 0) {
			    stares = this.state.stakeResults;
		    } else if (this.state.orgResults && this.state.orgResults.length > 0) {
			    orgres = this.state.orgResults;
		    }
		}
	}
        //if (this.state.results) {
            this.state.parentGrid.createNewComponent({
                w: 10,
                h: 5,
                component: Networker,
                componentArgs: { relres: relres, orgres: orgres, stares: stares }
            })
        //}
    }

    renderColumnValue = (params, data) => {
      if (data == "id") {
        if(params.row["url"].endsWith('.pdf')) {
		return (
		  <Button
		    variant={"contained"}
		    size={"small"}
		    onClick={() => this.handleAddBtnClick(params.row["id"], params.row["Title"])}
		    disabled={this.state.selectedDocuments.find(el => el.id === params.row["id"])}
		  >
		    + Add
		  </Button>
		);
	} else {
		return('');
	}
      } else {
        return params.row[data];
      }
    };

    setupColumns = (results) => {
        let cols = []

	if(results !== undefined) {
		if (results.length > 0) {
		    if(this.state.currentTab === 0 || (typeof this.state.currentTab === 'undefined' && this.state.filters.monitoredSources.enabled)) {
			    Object.keys(results[0]).forEach(e => {
				cols.push({
				    field: e,
				    headerClassName: "datagrid_headers",
				    headerName: e == "id" ? "Action" : e,
				    //headerName: e,
				    accessor: 'accessor',
				    editable: false,
				    hide: e.toLowerCase().includes("url"),
				    maxWidth: (e !== "Type" || e !== 'id')  ? getColumnWidth(results, e, e) : 150,
				    flex: 1,
				    renderCell: (params) => this.renderColumnValue(params, e),
				})
			    })
		   } else {
			    Object.keys(results[0]).forEach(e => {
				cols.push({
				    field: e,
				    headerClassName: "datagrid_headers",
				    //headerName: e,
				    accessor: 'accessor',
				    editable: false,
				    hide: e.toLowerCase().includes("id"),
				    flex: 1,
				})
			    })
		   }
		}
		else {
		    cols = []
		}
	}	
        return cols
    }

    render() {
        let mainContent;
    
        if (this.state.results === undefined && this.state.error === undefined && this.state.relengResults === undefined && this.state.orgResults === undefined && this.state.stakeResults === undefined || (this.state.context && this.state.isSummaryLoading)) {
            mainContent =
                <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>
        } else if (this.state.results !== undefined || this.state.relengResults !== undefined || this.state.orgResults !== undefined || this.state.stakeResults !== undefined) {
            const cols = this.setupColumns(this.state.results)
	    const relencols = this.setupColumns(this.state.relengResults)
	    const stakcols = this.setupColumns(this.state.stakeResults)
	    const orgcols = this.setupColumns(this.state.orgResults)

            //if (!this.state.filters.news.enabled || !this.state.filters.monitoredSources.enabled) {
	    if ((!this.state.results || this.state.results.length === 0) && (!this.state.relengResults || this.state.relengResults.length === 0) && (!this.state.orgResults || this.state.orgResults.length === 0) && (!this.state.stakeResults || this.state.stakeResults.length === 0)) {
			    mainContent = <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}>
				    <Alert variant="outlined" severity="warning">
					    No matches found. Try another tag combination or use tag autosuggester.
				    </Alert>
				    </Box>
	    } else {
	        if( this.state.tabsWithDataCount >= 2) {
			if(typeof this.state.currentTab === 'undefined') {
				let tabarr = [];
				if (this.state.filters.monitoredSources.enabled && this.state.relengResults.length > 0) {
					tabarr.push(0);
				}
				if (this.state.filters.bundestag.enabled || this.state.filters.ep.enabled || this.state.filters.books.enabled || this.state.filters.twitter.enabled) {
					tabarr.push(1);
				}
				if (this.state.filters.news.enabled) {
					tabarr.push(2);
				}
				if (this.state.filters.orgs.enabled) {
					tabarr.push(3);
				}
				if (this.state.filters.stakeholders.enabled && (this.state.stakeResults.length > 0)) {
					tabarr.push(4);
				}
				this.setState({currentTab: Math.min(...tabarr)});
			}

			mainContent =
			    <React.Fragment>
				<div className="card__tabs">
				    <div className="tabs">
					{this.state.filters.monitoredSources.enabled && (this.state.relengResults.length > 0) && (
						<div className={"tabs__tab " + (this.state.currentTab === 0 ? "is--active" : "")}
						onClick={() => (this.setState((prev) => ({ ...prev, currentTab: 0 })))}>General Sources
						</div>
					)}
					{(this.state.filters.bundestag.enabled || this.state.filters.ep.enabled || this.state.filters.books.enabled || this.state.filters.twitter.enabled) && (
						<div className={"tabs__tab " + (this.state.currentTab === 1 ? "is--active" : "")}
						onClick={() => (this.setState((prev) => ({ ...prev, currentTab: 1 })))}>Legacy Search
						</div>
					)}
					{this.state.filters.news.enabled && (
						<div className={"tabs__tab " + (this.state.currentTab === 2 ? "is--active" : "")}
						onClick={() => (this.setState((prev) => ({ ...prev, currentTab: 2 })))}>News
						</div>
					)}
					{this.state.filters.orgs.enabled && (
						<div className={"tabs__tab " + (this.state.currentTab === 3 ? "is--active" : "")}
						onClick={() => (this.setState((prev) => ({ ...prev, currentTab: 3 })))}>Organisations
						</div>
					)}
					{this.state.filters.stakeholders.enabled && (this.state.stakeResults.length > 0) && (
						<div className={"tabs__tab " + (this.state.currentTab === 4 ? "is--active" : "")}
						onClick={() => (this.setState((prev) => ({ ...prev, currentTab: 4 })))}>Stakeholders
						</div>
					)}
				    </div>
				</div>
				{this.state.currentTab === 1 ? (
				    <PDataGrid rows={this.state.results} showFilters={this.state.showFilters}
					columns={cols} parent={this} />
				) : this.state.currentTab === 2 ? (
				    this.renderNewsTab()
				) : this.state.currentTab === 3 ? (
				    <PDataGrid rows={this.state.orgResults}
					columns={orgcols} parent={this} />
				) : this.state.currentTab === 4 ? (
				    <PDataGrid rows={this.state.stakeResults}
					columns={stakcols} parent={this} />
				) : (
				    <PDataGrid rows={this.state.relengResults} showFilters={this.state.showFilters}
					columns={relencols} parent={this} />
				)}
			    </React.Fragment>
		    } else if (this.state.results && this.state.results.length > 0) {
			mainContent =
			    <PDataGrid rows={this.state.results} columns={cols} showFilters={this.state.showFilters}
				parent={this} />
		    } else if (this.state.relengResults && this.state.relengResults.length > 0) {
			mainContent =
			    <PDataGrid rows={this.state.relengResults} columns={relencols} showFilters={this.state.showFilters}
				parent={this} />
		    } else if (this.state.stakeResults && this.state.stakeResults.length > 0) {
			mainContent =
			    <PDataGrid rows={this.state.stakeResults} columns={stakcols}
				parent={this} />
		    } else if (this.state.orgResults && this.state.orgResults.length > 0) {
			mainContent =
			    <PDataGrid rows={this.state.orgResults} columns={orgcols}
				parent={this} />
		    }
		
            }
        } else {

            mainContent = <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}>
                <Alert variant="outlined" severity="error">
                    Sorry, we could not fetch your data (Server Error)
                </Alert>
            </Box>
        }
        return (
            <React.Fragment>
                <Paper className={"card"} elevation={0} style={{ overflow: "auto" }}>
                    <Stack sx={{ padding: "5px 15px 5px 15px" }} direction="row"
                        className={"drag-handle card__header"}>
                        <div className={"card__title"}>{this.properties.title}</div>
                        <div className={"card_controls"}>
                            <IconButton className={"control-button"} size={"small"}>
                                <HelpOutlineIcon className={"control-button__inner"} />
                            </IconButton>
                            <IconButton className={"control-button"} size={"small"} onClick={this.ShowNetworker}>
                                <PolylineIcon className={"control-button__inner"} />
                            </IconButton>
                            <IconButton className={"control-button"} size={"small"} onClick={this.onShowChartsClicked}>
                                <InsertChartOutlinedIcon className={"control-button__inner"} />
                            </IconButton>
                            <IconButton
                                className={"control-button " + (this.state.showFilters === true ? "is--active" : "")}
                                size={"small"}
                                onClick={() => (this.setState((prev) => ({
                                    ...prev,
                                    showFilters: !this.state.showFilters
                                })))}>
                                <FilterAltOutlinedIcon className={"control-button__inner"} />
                            </IconButton>

                            <IconButton className={"control-button"} size={"small"} onClick={this.onCloseClicked}>
                                <CloseIcon className={"control-button__inner"} />
                            </IconButton>
                        </div>
                    </Stack>
		    {this.state.summaryHtml && (
			     <div style={styles.summaryHtmlContainer}>
		      <div dangerouslySetInnerHTML={{ __html: this.state.summaryHtml }}></div></div>
		    )}
                    {mainContent}
		    {this.state.selectedDocuments.length > 0 && (
		      <>
			{console.log("selectedDocuments", this.state.selectedDocuments)}
			<Box className={"chat-doc-list"}>
			  {this.state.selectedDocuments.map((el) => (
			    <Chip
			      className="chat-doc-chip"
			      key={el.id}
			      title={el.title}
			      label={el.title}
			      variant="outlined"
			      onDelete={() => this.handleDocumentDelete(el.id)}
			    />
			  ))}
                </Box>
                <div className="footer-btn">
                  <Button variant={"contained"} onClick={() => this.onChatBtnClick()}>Start AI Chat</Button>
                </div>
              </>
            )}
                </Paper>
                <Snackbar open={this.state.newsResults !== undefined && this.state.showNewsFeedback}
                    autoHideDuration={5000}
                    onClose={(event) => this.setState(prev => ({ ...prev, showNewsFeedback: false }))}>
                    {
                        this.state.newsError !== undefined ?
                            (<Alert severity="error">Could not fetch your news :(</Alert>) :
                            (<Alert severity="success">Successfully fetched your news!</Alert>)
                    }
                </Snackbar>
            </React.Fragment>
        )
    }
}
