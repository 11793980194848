import React, { useEffect, useState, useMemo } from "react";
import GridComponent from "../SuperClasses/GridComponent";
import {
  Box,
  CircularProgress,
  Alert
} from "@mui/material";
import Graph from "graphology";
import { SigmaContainer, useLoadGraph, useSigma, useRegisterEvents, useSetSettings } from "@react-sigma/core";
import { useWorkerLayoutForceAtlas2 } from "@react-sigma/layout-forceatlas2";
import { useLayoutCircular } from "@react-sigma/layout-circular";
import "@react-sigma/core/lib/react-sigma.min.css";
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import getNodeProgramImage from "sigma/rendering/webgl/programs/node.image";
import chroma from "chroma-js";
import GSDocumentWidget from "../StandaloneWidgets/GSDocumentWidget";
import GSStakeholderCard from "../StandaloneWidgets/GSStakeholderCard";
import GSOrgCard from "../StandaloneWidgets/GSOrgCard";

const firebaseConfig = {
  apiKey: "AIzaSyCO2mWDYjSV6afTdMxCFAhoYJo_sb6zyzA",
  authDomain: "inq-app-402508.firebaseapp.com",
  projectId: "inq-app-402508",
  storageBucket: "inq-app-402508.appspot.com",
  messagingSenderId: "460212544539",
  appId: "1:460212544539:web:66ccca029c8930bc22c4be"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const sigmaStyle = { height: "100%", width: "100%" };

const RandomCircleGraph = ({ data, dataType, state }) => {
  const { positions, assign } = useLayoutCircular();
  const loadGraph = useLoadGraph();
  const sigma = useSigma();
  const registerEvents = useRegisterEvents();
  const setSettings = useSetSettings();
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [loadProgress, setLoadProgress] = useState({ loaded: 0, total: 0 });

  // For zoom-based node sizing
  const [zoomRatio, setZoomRatio] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const graph = new Graph();
        const existingNodeIds = new Set();
        const existingEdges = new Set();

        const fetchInBatches = async (collection, ids) => {
          const results = [];
          
          // Set total expected nodes for progress tracking
          setLoadProgress(prev => ({ ...prev, total: ids.length }));
          
          for (let i = 0; i < ids.length; i += 10) {
            const batch = ids.slice(i, i + 10);
            const q = query(collection, where('__name__', 'in', batch));
            const querySnapshot = await getDocs(q);
            results.push(...querySnapshot.docs);
            
            // Update loaded count after each batch
            setLoadProgress(prev => ({ 
              ...prev, 
              loaded: Math.min(prev.loaded + batch.length, prev.total) 
            }));
          }
          return results;
        };

        const DEFAULT_NODE_ALPHA = 0.93;

        const colorMapping = {
          9: '#FF6347',  // Keywords
          8: '#FFC600',  // Geotags
          11: '#4682B4', // Organizations
          13: '#32CD32'  // People/Stakeholders
        };

        const determineColor = (taxitem) => {
          const baseColor = colorMapping[taxitem.vid] || '#FF5733';
          return chroma(baseColor).alpha(DEFAULT_NODE_ALPHA).hex();
        };

        let mainNodes, mainCollection;

        if (dataType === 'relres') {
          mainNodes = data;
          mainCollection = 'content_nodes';
        } else {
          mainNodes = data;
          mainCollection = 'taxonomy';
        }

        // Initialize total node count for progress tracking
        setLoadProgress({ loaded: 0, total: mainNodes.length });
        
        const mainNodeDocs = await fetchInBatches(collection(db, mainCollection), mainNodes.map(item => item.id));

        const relatedIds = new Set();
        const edgesToAdd = [];
        const isolatedNodes = new Set();

        mainNodeDocs.forEach(doc => {
          const nodeId = doc.id;
          const fields = doc.data().fields || doc.data();

          if (!existingNodeIds.has(nodeId)) {
            const nodeAttributes = {
              label: fields.name || fields.title[0],
              size: 12,
              x: Math.random(),
              y: Math.random(),
              originalId: nodeId,
              // Initially mark as isolated
              isIsolated: true
            };

            if (dataType === 'relres') {
              nodeAttributes.color = chroma('#0000FF').alpha(DEFAULT_NODE_ALPHA).hex();
            } else if (dataType === 'orgres') {
              nodeAttributes.color = colorMapping[11];
            } else if (dataType === 'stares') {
              nodeAttributes.type = 'image';
              nodeAttributes.image = fields.field_image_link?.und?.[0]?.url || '/Unknown_person.jpg';
            }

            graph.addNode(nodeId, nodeAttributes);
            existingNodeIds.add(nodeId);
            isolatedNodes.add(nodeId);
          }

          const hasConnections = [];

          if (dataType === 'relres') {
            ['field_keywords', 'field_geotags', 'field_organisation_tags', 'field_people'].forEach(field => {
              if (fields[field]) {
                fields[field].forEach(id => {
                  relatedIds.add(id.toString());
                  edgesToAdd.push([nodeId, id.toString()]);
                  hasConnections.push(true);
                });
              }
            });
          } else {
            // For orgres and stares, use combined_tags
            if (fields.combined_tags) {
              fields.combined_tags.forEach(id => {
                relatedIds.add(id.toString());
                edgesToAdd.push([nodeId, id.toString()]);
                hasConnections.push(true);
              });
            }
          }

          // If this node has connections, mark it as not isolated
          if (hasConnections.length > 0) {
            isolatedNodes.delete(nodeId);
          }
        });

        // Update progress to include related nodes
        const relatedIdsArray = Array.from(relatedIds);
        setLoadProgress(prev => ({ 
          loaded: prev.loaded,
          total: prev.total + relatedIdsArray.length 
        }));

        // Fetch all related taxonomy terms
        const relatedDocs = await fetchInBatches(collection(db, 'taxonomy'), relatedIdsArray);

        relatedDocs.forEach(doc => {
          const relatedId = doc.id;
          const relatedItem = doc.data();

          if (!existingNodeIds.has(relatedId)) {
            const nodeAttributes = {
              label: relatedItem.name,
              size: 10,
              x: Math.random(),
              y: Math.random(),
              originalId: relatedId,
              color: determineColor(relatedItem),
              isIsolated: false // Related nodes are not isolated by definition
            };

            if (relatedItem.vid === "13") {
              nodeAttributes.type = 'image';
              nodeAttributes.image = relatedItem.field_image_link?.und?.[0]?.url || '/Unknown_person.jpg';
            }

            graph.addNode(relatedId, nodeAttributes);
            existingNodeIds.add(relatedId);
          }
        });

        // Add edges to the graph
        edgesToAdd.forEach(([source, target]) => {
          const edgeIdentifier = `${source}-${target}`;
          if (!existingEdges.has(edgeIdentifier)) {
            existingEdges.add(edgeIdentifier);
            
            // Only add edge if both nodes exist in the graph
            if (graph.hasNode(source) && graph.hasNode(target)) {
              graph.addEdge(source, target);
              
              // Mark nodes as not isolated since they have connections
              if (isolatedNodes.has(source)) {
                isolatedNodes.delete(source);
                graph.setNodeAttribute(source, "isIsolated", false);
              }
              if (isolatedNodes.has(target)) {
                isolatedNodes.delete(target);
                graph.setNodeAttribute(target, "isIsolated", false);
              }
            }
          }
        });

        // Create a weak gravity center - invisible node that gently anchors isolated nodes
        if (isolatedNodes.size > 0) {
          const gravityNodeId = "gravity-center";
          
          // Place the gravity node at the center
          graph.addNode(gravityNodeId, {
            label: "",
            size: 0.1,
            x: 0.5,
            y: 0.5,
            color: "rgba(0,0,0,0)", // Completely transparent
            hidden: true,
          });

          // Connect isolated nodes to gravity center with very weak connections
          isolatedNodes.forEach(nodeId => {
            // Create a unique ID for this connection
            const edgeId = `${nodeId}-${gravityNodeId}`;
            
            graph.addEdge(nodeId, gravityNodeId, {
              weight: 0.05, // Very weak connection
              color: "rgba(200,200,200,0.05)", // Very faint connection line
              size: 0.2,
              hidden: true, // Make the connection invisible
              id: edgeId
            });
            
            // Tag the node as having a gravity connection
            graph.setNodeAttribute(nodeId, "hasGravityConnection", true);
          });
        }

        const contentNodes = graph.filterNodes((node, attributes) => !attributes.type && attributes.color === chroma('#0000FF').alpha(DEFAULT_NODE_ALPHA).hex());
        const taxonomyNodes = graph.filterNodes((node, attributes) => attributes.type === 'image' || (attributes.color && attributes.color !== chroma('#0000FF').alpha(DEFAULT_NODE_ALPHA).hex()));

        const taxonomyDegrees = taxonomyNodes.map((node) => graph.degree(node));
        const minDegree = Math.min(...taxonomyDegrees);
        const maxDegree = Math.max(...taxonomyDegrees);
        const minSize = 4, maxSize = 10;

        function logScale(degree) {
          if (maxDegree === minDegree) return 1;
          const adjustedDegree = degree - minDegree + 1;
          const logMax = Math.log(maxDegree - minDegree + 1);
          return Math.log(adjustedDegree) / logMax;
        }

        contentNodes.forEach((node) => {
          graph.setNodeAttribute(node, "size", 10);
          graph.setNodeAttribute(node, "baseSize", 10); // Store base size for zoom scaling
        });

        taxonomyNodes.forEach((node) => {
          const degree = graph.degree(node);
          const normalizedSize = logScale(degree);
          const size = minSize + normalizedSize * (maxSize - minSize);
          graph.setNodeAttribute(node, "size", size);
          graph.setNodeAttribute(node, "baseSize", size); // Store base size for zoom scaling
        });

        loadGraph(graph);
        assign();

        // Register the events
        registerEvents({
          enterNode: (event) => setHoveredNode(event.node),
          leaveNode: () => setHoveredNode(null),
          doubleClickNode: (event) => {
            event.preventSigmaDefault();
            const node = event.node;
            
            // Ignore the gravity center
            if (node === "gravity-center") return;
            
            const originalId = graph.getNodeAttribute(node, 'originalId');
            const color = graph.getNodeAttribute(node, 'color');
            if (color === undefined) {
              state.parentGrid.createNewComponent({w:3,h:6, component:GSStakeholderCard,  componentArgs:{"id":originalId}});
            } else {
              const ntype = Object.keys(colorMapping).find(key => colorMapping[key] === color.toUpperCase().substring(0, 7));
              if(!ntype) {
                state.parentGrid.createNewComponent({w:3,h:6, component:GSDocumentWidget,  componentArgs:{"id":originalId}});
              } else if(ntype === '11') {
                state.parentGrid.createNewComponent({w:3,h:6, component:GSOrgCard,  componentArgs:{"id":originalId}});
              } else if(ntype === '13') {
                state.parentGrid.createNewComponent({w:3,h:6, component:GSStakeholderCard,  componentArgs:{"id":originalId}});
              }
            }
          },
          // Safe way to handle camera changes without using .on
          cameraMoved: ({ x, y, angle, ratio }) => {
            setZoomRatio(ratio);
          }
        });

      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [assign, loadGraph, registerEvents, data, dataType]);

  useEffect(() => {
    setSettings({
      nodeReducer: (node, data) => {
        const graph = sigma.getGraph();
        const newData = { ...data, highlighted: data.highlighted || false };

        // Ignore the gravity center node
        if (node === "gravity-center") {
          return { ...newData, hidden: true, size: 0.1 };
        }

        // Apply zoom-based scaling to node size
        const baseSize = graph.getNodeAttribute(node, "baseSize") || newData.size;
        const scaledSize = baseSize / Math.pow(zoomRatio || 1, 0.5);
        newData.size = scaledSize;

        if (hoveredNode) {
          if (node === hoveredNode || graph.neighbors(hoveredNode).includes(node)) {
            newData.highlighted = true;
            // Keep the image for highlighted nodes
            newData.image = data.image;
          } else {
            newData.color = "#F0F0F0";
            newData.highlighted = false;
            // Remove the image for non-highlighted nodes
            delete newData.image;
            newData.type = 'circle'; // Change type to circle for non-image nodes
          }
        } else {
          // Restore the image when no node is hovered
          newData.image = data.image;
          newData.type = data.image ? 'image' : 'circle';
        }
        return newData;
      },
      edgeReducer: (edge, data) => {
        const graph = sigma.getGraph();
        const newData = { ...data, hidden: false };

        // Always hide edges connected to the gravity center
        const [source, target] = graph.extremities(edge);
        if (source === "gravity-center" || target === "gravity-center") {
          newData.hidden = true;
          return newData;
        }

        if (hoveredNode && !graph.extremities(edge).includes(hoveredNode)) {
          newData.hidden = true;
        }
        return newData;
      },
    });
  }, [hoveredNode, setSettings, sigma, zoomRatio]);

  if (isLoading) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 1,
          padding: 2
        }}
      >
        <CircularProgress sx={{ marginBottom: 2 }} />
        <Alert icon={false} severity="info" sx={{ width: '80%', maxWidth: '400px' }}>
          {loadProgress.loaded} nodes loaded of {loadProgress.total}
        </Alert>
      </Box>
    );
  }

  return null;
};

/*const Fa2 = () => {
  // Adjust force atlas settings to prevent excessive repulsion
  const { start, kill } = useWorkerLayoutForceAtlas2({ 
    settings: { 
      slowDown: 10,
      gravity: 0.1,               // Increased from 0.02 to pull nodes toward center
      gravitationalConstant: 0.1, // Controls the strength of repulsion between components
      scalingRatio: 2,            // Adjusts overall force strength (lower = less repulsion)
      barnesHutOptimize: true,    // Optimization for large graphs
      barnesHutTheta: 0.5,        // Accuracy of approximation (lower = more accurate)
      startingIterations: 10,     // Fast initial organization
      iterationsPerRender: 10,    // How many iterations per render
      linLogMode: false,          // When true, increases repulsion for distant nodes (leave false)
      outboundAttractionDistribution: false, // Distributes attraction along outbound links
      adjustSizes: true,          // Takes node sizes into account
      strongGravityMode: true,    // Keeps disconnected nodes from flying away
      dissuadeHubs: false,        // When true, prevents hubs from being too central
    } 
  });

  useEffect(() => {
    start();
    return () => {
      kill();
    };
  }, [start, kill]);

  return null;
};*/

const Fa2 = () => {
  const [slowDown, setSlowDown] = useState(1);
  
  // Create settings object with current slowDown value
  const settings = useMemo(() => ({
    slowDown: slowDown,
    gravity: 0.1,               
    gravitationalConstant: 0.1, 
    scalingRatio: 2,            
    barnesHutOptimize: true,    
    barnesHutTheta: 0.5,        
    startingIterations: 10,     
    iterationsPerRender: 10,    
    linLogMode: false,          
    outboundAttractionDistribution: false, 
    adjustSizes: true,          
    strongGravityMode: true,    
    dissuadeHubs: false,
  }), [slowDown]);
  
  // Use the layout with current settings
  const { start, kill } = useWorkerLayoutForceAtlas2({ settings });

  useEffect(() => {
    // Start the layout
    start();
    
    // Initial cooling phase (first 3 seconds)
    const coolingInterval = setInterval(() => {
      setSlowDown(prevValue => prevValue * 1.5); // Increase slowDown by 50% each time
      console.log("Slowdown");
    }, 5000);
    
    // Eventually, stop the layout completely
    const stopTimer = setTimeout(() => {
      clearInterval(coolingInterval);
      kill();
      console.log("Layout stopped after cooling phase");
    }, 300000);
    
    // Clean up
    return () => {
      clearInterval(coolingInterval);
      clearTimeout(stopTimer);
      kill();
    };
  }, [start, kill]);

  return null;
};

/*const Fa2 = () => {
  // Use the original layout settings but with just enough gravity to prevent extreme drift
  const { start, kill } = useWorkerLayoutForceAtlas2({ 
    settings: { 
      slowDown: 10,
      gravity: 0.02, // Just a small amount of gravity to prevent extreme drift
      // No strongGravityMode or other settings that would change clustering behavior
    } 
  });

  useEffect(() => {
    start();
    return () => {
      kill();
    };
  }, [start, kill]);

  return null;
};*/

export default class Networker extends GridComponent {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.initComponent({ "type": "Networker", "title": "Networker" });
  }

  componentDidMount() {
    console.log(this.state);
  }

  render() {
    const { relres, orgres, stares } = this.props;
    let data, dataType;

    if (relres && relres.length > 0) {
      data = relres;
      dataType = 'relres';
    } else if (orgres && orgres.length > 0) {
      data = orgres;
      dataType = 'orgres';
    } else if (stares && stares.length > 0) {
      data = stares;
      dataType = 'stares';
    }

    return (
      <div className={"card"} sx={{ width: "100%", height: "100%" }} style={{ overflow: "hidden", userSelect: "none" }}>
        {this.renderTopBar()}
        <Box sx={{ width: '100%', height: 'calc(100% - 50px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SigmaContainer
            style={sigmaStyle}
            settings={{
              allowInvalidContainer: true,
              nodeProgramClasses: { image: getNodeProgramImage() },
              defaultNodeType: 'image'
            }}
          >
            <RandomCircleGraph data={data} dataType={dataType} state={this.state}/>
            <Fa2 />
          </SigmaContainer>
        </Box>
      </div>
    );
  }
}
