import React from "react";
import GridComponent from "../SuperClasses/GridComponent";
import { CircularProgress, Paper, TextField, InputLabel, MenuItem, FormControl, Select, Box, Alert } from "@mui/material";
import { toast } from "react-toastify";
import _ from "lodash";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { GET_GS, DEFAULT_FETCH_HEADERS } from "../config";
import GSDocumentWidget from "./GSDocumentWidget.js";

let LIST = [];

async function populateList() {
  try {
    //this is actually only a hash of the API key
    //see endpoint for more info
    const response = await fetch('https://jotup.co/api/listfeeds?api_key=566439a8ac1443d0be4165e9c4034bb0&book=2700072');
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const feedresponse = await response.json();

    if (!feedresponse.response || !feedresponse.response.docs) {
      throw new Error('Invalid response format: missing response or docs');
    }

    LIST = feedresponse.response.docs.map(doc => ({
      label: doc.ss_title,
      value: doc.is_nid
    }));

  } catch (error) {
    console.error('Error fetching feed:', error);
    // LIST remains an empty array in case of error
  }
}

// Call the function to populate LIST
populateList();

export default class NewsletterWidget extends GridComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      topic: null,
      loading_intro: false,
      loading_summary: false,
      loading_links: false,
      intro: null,
      topics: [],
      links: [],
      date: null,
      displayError: false,
      errorMessage: "",
      waitingstate: false,
      nids: [],
      summaryHtml: null,
      fetchingStage: null,
      newsCount: 0,
      processedNewsCount: 0
    };
    this.initComponent({ type: "Newsletter", title: "Advanced Newsfeeds" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loading_summary && !prevState.loading_summary) {
      this.fetchSummaryData();
    }
  }

  async fetchSummaryData() {
    try {
      this.setState({ fetchingStage: 'fetch', newsCount: 0, processedNewsCount: 0 });
      const response = await fetch(`https://jotup.co/api/fetch?api_key=ZiS9csmECPOKqD9a&channel_id=${this.state.topic}`);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (!data || data.length === 0) {
        this.setState({ 
          errorMessage: "Newsfeed is empty (no news)",
          loading_summary: false,
          displayError: true,
          fetchingStage: null
        });
        return;
      }

      this.setState({ topics: data, newsCount: data.length, fetchingStage: 'llm' });

      const formData = new FormData();
      formData.append('news_items', JSON.stringify(data));

      const llmResponse = await fetch(`https://jotup.co/api/llm?api_key=ZiS9csmECPOKqD9a&channel_id=${this.state.topic}`, {
        method: 'POST',
        body: formData
      });
      const llmData = await llmResponse.json();
      const summaryHtml = llmData.choices[0].message.content;

      this.setState({ summaryHtml, fetchingStage: 'gs' });

      const nids = await Promise.all(data.map((el, index) => this.fetchNidData(el, index)));
      const newNids = {};
      nids.forEach((nid, index) => {
        newNids[this.state.topics[index].nid] = nid || null;
      });

      this.setState({ 
        nids: { ...this.state.nids, ...newNids }, 
        loading_summary: false,
        displayError: false,
        errorMessage: "",
        fetchingStage: null,
        processedNewsCount: 0
      });

    } catch (error) {
      console.error("Error fetching data: ", error);
      this.setState({ 
        displayError: true, 
        errorMessage: "Couldn't fetch the feed. Try again or narrow down newsfeed query to reduce the number of news.",
        loading_summary: false,
        fetchingStage: null
      });
    }
  }

  async fetchNidData(el, index) {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": el.nid })
      };
      const response = await fetch(GET_GS, requestOptions);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const json = await response.json();

      if (json) {
        console.log(json.doc.id); // Logging nid to console
        this.setState(prevState => ({
          processedNewsCount: prevState.processedNewsCount + 1
        }));
        return json.doc.id;
      }
      return null;

    } catch (error) {
      console.error(`Error fetching data for nid ${el.nid}: `, error);
      return null;
    }
  }

  handleNDivClick = (event) => {
    const target = event.target;
    this.state.parentGrid.createNewComponent({w:3,h:6, component:GSDocumentWidget,  componentArgs:{"id":target.dataset.id}})
  }

  handleChange = (event) => {
    this.setState({ 
      topic: event.target.value, 
      loading_summary: true, 
      displayError: false,
      errorMessage: "",
      summaryHtml: null,
      topics: [],
      nids: [],
      fetchingStage: null,
      newsCount: 0,
      processedNewsCount: 0
    });
  };

  render_main_content() {
    if (!this.state.topic) {
      return <p className="helper-text">Please select a newsfeed.</p>;
    }

    if (this.state.loading_summary) {
      return (
        <Box className="flex-center" display="flex" flexDirection="column" alignItems="center">
          <CircularProgress />
          <Box sx={{ mt: 2 }}>
            {this.state.fetchingStage === 'fetch' && (
              <Alert icon={false} severity="info">Gathering news</Alert>
            )}
            {this.state.fetchingStage === 'llm' && (
              <Alert icon={false} severity="info">Metasummarising {this.state.newsCount} news items</Alert>
            )}
            {this.state.fetchingStage === 'gs' && (
              <Alert icon={false} severity="info">Loaded {this.state.processedNewsCount} of {this.state.newsCount} items</Alert>
            )}
          </Box>
        </Box>
      );
    } else if (this.state.displayError) {
      return (
        <Box display="flex" height="75%" alignItems="center" justifyContent="center">
          <Alert variant="outlined" severity="error">
            {this.state.errorMessage}
          </Alert>
        </Box>
      );
    } else {
      return (
        <div>
          <h2>InQ Daily - {LIST.find(el => el.value == this.state.topic)?.label}</h2>
          <p>
            <small>
              <a href={`https://jotup.co/node/${this.state.topic}`} target="_blank" rel="noopener noreferrer">
                <Box component="span" sx={{ display: 'inline-flex', verticalAlign: 'middle', fontSize: '1rem' }}>
                  <ExitToAppIcon fontSize='0.9rem'/>
                </Box>
                &nbsp;Manage newsfeed
              </a>
            </small>
          </p>
          {this.state.summaryHtml && (
            <div dangerouslySetInnerHTML={{ __html: this.state.summaryHtml }}></div>
          )}
          <hr></hr>
          <hr></hr>
          <h3>News items:</h3>
          <hr></hr>
          {this.state.topics?.map((el, index) => {
            const nid = this.state.nids[el.nid];
            if(!nid) {
              return (
                <div key={index} className="block-gap">
                  <br/><a className="underline-link large-link" href={el.url} target="_blank" rel="noopener noreferrer">{el.title}</a>
                  <div dangerouslySetInnerHTML={{__html: el.summary}}></div>
                </div>
              )
            } else {
              return (
                <div key={index} className="block-gap">
                  <br/><div className="link large-link underline-link span" onClick={this.handleNDivClick} data-id={el.nid} >{el.title}</div>
                  <div dangerouslySetInnerHTML={{__html: el.summary}}></div>
                </div>
              )
            }
          })};
        </div>
      );
    }
  }

  render() {
    return (
      <Paper className="card">
        {this.renderTopBar()}
        <div className="card__body">
          <div className="card__body-inner">
            <div className="form-container">
              <FormControl fullWidth size="small" className="dropdown-form">
                <InputLabel>Select newsfeed</InputLabel>
                <Select
                  value={this.state.topic}
                  label="Select newsfeed"
                  onChange={(e) => this.handleChange(e)}
                >
                  {_.map(LIST, el => <MenuItem value={el.value} key={el.value}>{el.label}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="card__body-inner">{this.render_main_content()}</div>
        </div>
      </Paper>
    );
  }
}
