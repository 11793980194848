import GridComponent from "../SuperClasses/GridComponent";
import { Box, Divider, IconButton, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import BarChart from "./BarChart";
import { requestCount, requestResample } from "../../utils/backendConnector";
import TimeSeriesChart from "./TimeChart";


export default class SearchResultCharts extends GridComponent {
    constructor(props) {
        super(props);
        this.state = { ...this.state, "chartTabIndex": 0 }
    }


    drawStakeholderChart = () => {
        if (this.state.occurrences === undefined)
            return;
        let data = this.state.occurrences;
	let sortedEntries = Object.entries(data)
		.sort((a, b) => b[1] - a[1])  // Sort based on the values in descending order
		.slice(0, 10);  // Take the first 10 entries

	    // Separate the sorted entries into keys and values
	    let keys = sortedEntries.map(entry => entry[0]);
	    let values = sortedEntries.map(entry => entry[1]);
        return <Box width={"95%"} height={"95%"} overflow={"auto"} m={2} > <BarChart height="100%" seriesName={"Stakeholder Occurrences"} categories={keys} values={values} /></Box>
    }

    // drawDotChart = () =>
    // {
    //     if(this.state.DoT === undefined)
    //         return;

    //     let data = this.state.DoT;
    //     let dates = Object.keys(data).map(d => new Date(d / 1000/1000).toDateString());
    //     let docs = Object.values(data);

    //     return <Box flex={1} width={"95%"} height={"95%"} overflow={"auto"} m={2}> <TimeSeriesChart seriesName={"Documents over Time"} parent={this} categories={dates} values={docs}/></Box>
    // }

    drawDotChart = () => {
        if (this.state.DoT === undefined)
            return;

        let data = this.state.DoT;
        let dates = Object.keys(data).map(d => {
            let timestampInMilliseconds = parseInt(d) / 1000000;
            return new Date(timestampInMilliseconds).toDateString();
        });
        // let docs = Object.values(data);
        let docs = Object.values(data);
        let numOcurrencyValues = docs.map(obj => obj.num_ocurrency);
        let documentsDetails = docs.map(obj => obj.documents);

        return <Box flex={1} width={"95%"} height={"95%"} overflow={"auto"} m={2}> <TimeSeriesChart seriesName={"Documents over Time"} parent={this} categories={dates} values={numOcurrencyValues} documents={documentsDetails} /></Box>

    }


    componentDidMount() {
        this.setState((prev) => ({
            ...prev,
            DoT: this.props["data"]["occurrences_over_time"],
            occurrences: this.props["data"]["occurrences"]
        }))
    }

    onChartTabSwitch = (event, value) => {
        this.setState({ chartTabIndex: value })
    }


    render() {
        let tabContent;

        if (this.state.chartTabIndex === 0)
            tabContent = this.drawDotChart()
        else if (this.state.chartTabIndex === 1)
            tabContent = this.drawStakeholderChart()
        return (
            <Paper elevation={6} sx={{ width: "100%", height: "100%" }} style={{ overflow: "hidden" }}>
                <Stack height={"100%"} overflow={"hidden"}>
                    <Stack sx={{ padding: "10px 15px 10px 15px" }} justifyContent={"flex-end"} direction="row" className={"drag-handle"}>
                        <IconButton size={"small"} sx={{ align: "right" }} onClick={this.onCloseClicked}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Divider />
                    <Tabs value={this.state.chartTabIndex} onChange={this.onChartTabSwitch} variant="fullWidth">
                        <Tab value={0} index={0} label="Documents over time" />
                        <Tab value={1} index={1} label="Stakeholder occurrences" />
                    </Tabs>
                    {tabContent}
                </Stack>
            </Paper>
        );
    }
}
