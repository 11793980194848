import GridComponent from "../SuperClasses/GridComponent";
import {
    Autocomplete,
    //Paper,
    Stack,
    Tab,
    Tabs, TextField,
    //Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {Component} from "react";
import {DataGrid} from "@mui/x-data-grid";
import StakeholderCard from "../StandaloneWidgets/StakeholderCard";
import GSStakeholderCard from "../StandaloneWidgets/GSStakeholderCard";
import GSOrgCard from "../StandaloneWidgets/GSOrgCard";
import TweetWidget from "../StandaloneWidgets/TwitterWidget";
import DocumentWidget from "../StandaloneWidgets/DocumentWidget";
import GSDocumentWidget from "../StandaloneWidgets/GSDocumentWidget";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EPDocumentWidget from "../StandaloneWidgets/EPDocumentWidget";
import {DOCUMENT_SERVICE} from "../config";
import { PDFWidget } from "../StandaloneWidgets/PDFWidget";


export default class PDataGrid extends Component
{
    constructor(props) {
        super(props);
        this.state = {"data":props, "currentTab":0, "filter":{}}
    }


    onTabChange = (event, value) =>
    {
        this.setState({"currentTab":value})
    }


    onCellClickHandler = (event,context) =>
    {
        if(event.field === "Name")
        {
            //this.state.data.parent.state.parentGrid.createNewComponent({w:3,h:6, component:StakeholderCard,  componentArgs:{"id":event.row["Stakeholder ID"]}})
	    this.state.data.parent.state.parentGrid.createNewComponent({w:3,h:6, component:GSStakeholderCard,  componentArgs:{"id":event.row["id"]}})
        }
        else if(event.field === "Title")
        {

            if(event.row["Type"] === "Tweet")
            {
                this.state.data.parent.state.parentGrid.createNewComponent({w:3,h:4, component:TweetWidget,  componentArgs:{"id":event.row["id"]}})
            }
            else if(event.row["Type"] === "Book")
            {
                const fileId = event.row["id"]
                this.state.data.parent.state.parentGrid.createNewComponent({ w: 6, h: 6, component: PDFWidget, componentArgs: { pdfUrl: `${DOCUMENT_SERVICE}/books/${fileId}/pdf` } })
            }
            else if(event.row['Type'] === 'EP Document')
            {
                this.state.data.parent.state.parentGrid.createNewComponent({w:3,h:5, component:EPDocumentWidget,  componentArgs:{"id":event.row["id"]}})
            }
	    else if(Number.isInteger(Number(event.row['id']))) {
                this.state.data.parent.state.parentGrid.createNewComponent({w:3,h:6, component:GSDocumentWidget,  componentArgs:{"id":event.row["id"]}})
            }
            else
            {
                this.state.data.parent.state.parentGrid.createNewComponent({w:3,h:6, component:DocumentWidget,  componentArgs:{"id":event.row["id"]}})
            }
        }else if(event.field === 'Org Name') {
	    this.state.data.parent.state.parentGrid.createNewComponent({w:3,h:6, component:GSOrgCard,  componentArgs:{"id":event.row["id"]}})
	}
    }

    getUniqueValues(field)
    {
        let values = []
        this.props.rows.forEach(e =>
        {
            values.push(e[field])
        })
        return [... new Set(values)]
    }

    onFilterChange = (category, event, value, reason) =>
    {
        if (this.state[category] === undefined)
            this.setState({filter:{[category]:[value]}})
        else
            this.setState({filter:{[category]:[...value]}})
    }

    renderTabContent = () =>
    {
        if(this.state.currentTab === 0)
        {
            return(
                <Stack sx={{"marginTop":2}}>
                    <Stack direction={"row"} width={"100%"}>
                        <Autocomplete
                            id={"type"}
                            value={this.state.filter.type && this.state.filter.type.length >= 0 ? this.state.filter.type[0] :  []}
                            fullWidth
                            autoHighlight
                            multiple
                            onChange={(event, value, reason) => this.onFilterChange("type", event,value,reason)}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Filter Document Types"
                            />)}
                        options={this.getUniqueValues("Type")}/>
                    </Stack>
                </Stack>)
        }
        else if (this.state.currentTab === 1)
        {
            return(
                <Stack sx={{"marginTop":2}}>
                    <Stack direction={"row"} width={"100%"}>
                        <Autocomplete
                            id={"source"}
                            value={this.state.filter.source && this.state.filter.source.length >= 0 ? this.state.filter.source[0] : []}
                            fullWidth
                            autoHighlight
                            multiple
                            onChange={(event, value, reason) => this.onFilterChange("source", event,value,reason)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Filter Sources"
                                />)}
                            options={this.getUniqueValues("Source")}/>
                    </Stack>
                </Stack>)
        }
        else if (this.state.currentTab === 2)
        {
            return(
                <Stack sx={{"marginTop":2}}>
                    <Stack direction={"row"} width={"100%"}>
                        <Autocomplete
                            id={"stakeholder"}
                            value={this.state.filter.stakeholder && this.state.filter.stakeholder.length >= 0 ? this.state.filter.stakeholder[0] : []}
                            fullWidth
                            autoHighlight
                            multiple
                            onChange={(event, value, reason) => this.onFilterChange("stakeholder", event,value,reason)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Filter Stakeholders"
                                />)}
                            options={this.getUniqueValues("Stakeholder")}/>
                    </Stack>
                </Stack>)
        }
    }

    filterRows = () =>
    {

        this.rows = []
        if(Object.keys(this.state.filter).length > 0)
        {
            this.props.rows.forEach(e =>
            {
                let shouldSkip = false;
                Object.keys(this.state.filter).forEach(x =>
                {
                    if (Object.keys(e).map(e => e.toLowerCase()).includes(x) !== undefined)
                    {
                        if (this.state.filter[x][0].length >0 && !this.state.filter[x][0].includes(e[Object.keys(e)[Object.keys(e).map(e => e.toLowerCase()).indexOf(x)]]))
                        {
                            shouldSkip = true;
                            return
                        }

                    }
                })
                if (!shouldSkip)
                    this.rows.push(e)
            })
        }
        else {
            this.rows = this.props.rows
        }
    }

    renderFilters  = () =>
    {
        if(this.props.showFilters) {
            return (
                <div className={"card__filter"}>
                    <div className="filter__tabs">
                        <div className="filter__label">Filter by:</div>
                        <Tabs sx={{marginTop: -1.7}} value={this.state.currentTab} onChange={this.onTabChange}>
                            <Tab className={"tabs__tab"} label="Documents"/>
                            <Tab className={"tabs__tab"} label="Sources"/>
                            <Tab className={"tabs__tab"} label="Stakeholders"/>
                        </Tabs>
                    </div>
                    <div className={"filter__body"}>
                        {this.renderTabContent()}
                    </div>
                </div>
            )
        }
        else {
            return(<div></div>)
        }
    }

    render() {
        this.filterRows();
        return (
            <Stack direction={"column"} height={"100%"} >
                {this.renderFilters()}
                <div className={"card__table"}>
                    <DataGrid
                        columns={this.props.columns}
                        rows={this.rows}
                        onCellClick={this.onCellClickHandler}
                        getCellClassName={(params) => {
                            if ((params.field === "Title" || params.field === "Name" || params.field === "Org Name"))
                            {
                                return "clickable_datagrid_cells";
                            }
                            //if ((params.field !== "Title") && (params.field !== "Stakeholder") || (this.rows.find(x => x.id === params.id) !== undefined && this.rows.find(x => x.id === params.id)["Stakeholder ID"] === null)) {
                            //    return '';
                            //}
                            //return "clickable_datagrid_cells";
                        }}/>
                </div>
            </Stack>
        );
    }
}
