import React from "react";
import GridComponent from "../SuperClasses/GridComponent";
import {
    Box,
    CircularProgress,
    Paper,
} from "@mui/material";
import { FETCH_ORGANISATION, DEFAULT_FETCH_HEADERS } from "../config";
import Search from "./Search";

export default class GSOrgCard extends GridComponent {
    constructor(props) {
        super(props);
        this.initComponent({ "type": "Organisation", "title": "Organisation Profile" })
    }

    componentDidMount() {
        if (!this.promise) {
            const requestOptions = {
                method: 'POST',
                headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ "oid": this.props["id"] })
            };

            this.promise = fetch(FETCH_ORGANISATION, { ...requestOptions })
                .then((response) => response.json())
                .then((data) => this.setState({ orgData: data }))
                .catch((error) => this.setState({ error: error }));
        }
    }

    handleTagClick = (event) => {
        event.preventDefault();
        const tagText = event.target.textContent;
        const searchComponent = this.props.parentGrid.state.items.find(item => item.component === Search);
        if (searchComponent && searchComponent.ref.current) {
            searchComponent.ref.current.addKeyword(tagText);
        }
    }

    handleLocationTagClick = (event) => {
        event.preventDefault();
        const locationText = event.target.textContent;
        const searchComponent = this.props.parentGrid.state.items.find(item => item.component === Search);
        if (searchComponent && searchComponent.ref.current) {
            searchComponent.ref.current.addLocation(locationText);
        }
    }

    handleDivClick = (event) => {
        const target = event.target;
        if (event.target !== event.currentTarget) {
            this.state.parentGrid.createNewComponent({w:3,h:6, component:GSOrgCard,  componentArgs:{"id":target.dataset.id}})
        }
    }

    render() {
        if (this.state.orgData === undefined && this.state.error === undefined) {
            return (
                <div className={"card"} sx={{ width: "100%", height: "100%" }} style={{ overflow: "hidden", userSelect: "none" }}>
                    {this.renderTopBar()}
                    <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>
                </div>)
        } else {
            return (
                <Paper elevation={0} className="card">
                    {this.renderTopBar()}
                    <div className="stakeholder">
                        <div className="stakeholder__title">{this.state.orgData.doc.name}</div>
                        <div className="stakeholder__subtitle link org" onClick={this.handleDivClick} dangerouslySetInnerHTML={{__html: this.state.orgData.doc.renderedp}}></div>
                    </div>
                    <div className="card__scroll-area">
                        <div className="card-section">
                            <p>
                                <small
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.orgData.doc.renderedkw.replace(
                                            /<a\s+href="#"\s+value="\d+">([^<]+)<\/a>/g,
                                            '<span class="link">$1</span>'
                                        )
                                    }}
                                    onClick={this.handleTagClick}
                                ></small>
                            </p>
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Metadata</h3>
                                </div>
                            </div>
                            <div className="card-section__body">
                                <div className="meta">
                                    <div className="meta__title">Also known as:</div>
                                    <div className="meta__data org" onClick={this.handleDivClick} dangerouslySetInnerHTML={{__html: this.state.orgData.doc.rendereds}}></div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Location(s):</div>
                                    <div 
                                        className="meta__data" 
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.orgData.doc.renderedlo.replace(
                                                /<a\s+href="#"\s+value="\d+">([^<]+)<\/a>/g,
                                                '<span class="link">$1</span>'
                                            )
                                        }}
                                        onClick={this.handleLocationTagClick}
                                    ></div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Socials:</div>
                                    <a href={this.state.orgData.doc.field_twitter?.[0]?.value ? "https://x.com/" + this.state.orgData.doc.field_twitter[0].value : ''} target="_blank" rel="noopener noreferrer" download>{this.state.orgData.doc.field_twitter?.[0]?.value ? 'Twitter' : ''}</a> 
                                    <a href={this.state.orgData.doc.field_linkedin?.[0]?.value ? "https://linkedin.com/" + this.state.orgData.doc.field_linkedin[0].value : ''} target="_blank" rel="noopener noreferrer" download>{this.state.orgData.doc.field_linkedin?.[0]?.value ? 'Linkedin' : ''}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            );
        }
    }
}
