import React from "react";
import {
  Box,
  Paper,
  Grid,
  TextField,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { pdfjs } from "react-pdf/dist/umd/entry.webpack5";
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/esm/entry.webpack5";
import { Pagination } from "@mui/lab";
import GridComponent from "../SuperClasses/GridComponent";
import * as dayjs from "dayjs";

import {
  BASE_URL,
  CHAT_BOT_URL,
  DEFAULT_FETCH_HEADERS,
  GET_GS,
} from "../config";
pdfjs.GlobalWorkerOptions.workerSrc =
  BASE_URL.replace("/api", "") + "/scripts/pdf.worker.min.js";

export class DocumentChatWidget extends GridComponent {
  constructor(props) {
    super(props);
    this.pdfWrapper = React.createRef();
    this.initComponent({ type: "DocumentChatWidget", title: "Document Chat" });
    this.state = {
      ...props,
      query: "",
      documentDataSet: [],
      currentPage: 1,
      documentLoader: true,
      document_ids: [],

      chatId: null,
      chatLoader: true,
      chatError: null, // need to handle the error

      selectedDocument: null,

      conversation: [],
      responseError: null, // need to handle the error
      responseLoader: false,
    };
  }

  componentDidMount() {
    if (!this.fetchDocumentPromise) {
      this.fetchDocumentPromise = this.fetchDocumentDetails();
    }
  }

  componentDidUpdate() {
    if (
      this.state.documentDataSet.length > 0 &&
      this.state.document_ids.length === 0 &&
      this.state.documentLoader
    ) {
      this.uploadDocuments();
    }

    if (
      this.state.documentDataSet.length > 0 &&
      this.state.document_ids.length > 0 &&
      !this.state.chatId &&
      !this.state.chatError &&
      this.state.chatLoader
    ) {
      this.createChatID();
    }

    if (this.state.conversation.length > 0 && this.state.responseLoader) {
      this.fetchChatCompletion();
    }
  }

  fetchDocumentDetails = async () => {
    const promises = this.state.documents.map((item) => {
      const requestOptions = {
        method: "POST",
        headers: {
          ...DEFAULT_FETCH_HEADERS().headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: item.id }),
      };

      return fetch(
        GET_GS,
        { ...requestOptions }
      ).then((response) => {
        return response.json();
      });
    });

    Promise.all(promises).then((results) => {
      const dataSet = results.map((result) => {
        return {
          id: result.doc._id,
          //pdf: result.pdf,
          url: result.doc.fields['field_url:url'][0],
          title: result.doc.fields['title'][0],
        };
      });

      this.setState({
        documentDataSet: dataSet,
        selectedDocument: dataSet[0]?.id,
      });
    });
  };

  uploadDocuments = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        ...DEFAULT_FETCH_HEADERS().headers,
        "Content-Type": "application/json",
      },
    };

    const promises = this.state.documentDataSet.map((item) => {
      return fetch(`${CHAT_BOT_URL}/api/document/upload?doc_url=${item.url}`, {
        ...requestOptions,
      }).then((response) => response.json());
    });

    Promise.all(promises).then((results) => {
      const document_ids = results.map((result, index) => {
        return { ...this.state.documentDataSet[index], document_id: result };
      });
      this.setState({ document_ids: document_ids, documentLoader: false });
    });
  };

  createChatID = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        ...DEFAULT_FETCH_HEADERS().headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        document_ids: this.state.document_ids.map((el) => el.document_id),
      }),
    };

    fetch(`${CHAT_BOT_URL}/api/conversation/`, { ...requestOptions })
      .then((response) => response.json())
      .then((data) =>
        this.setState({ chatId: data.id, chatError: null, chatLoader: false })
      )
      .catch((error) => this.setState({ chatError: error, chatLoader: false }));
  };

  fetchChatCompletion = async () => {
    const lastChat =
      this.state.conversation[this.state.conversation.length - 1];
    const sse = new EventSource(
      `${CHAT_BOT_URL}/api/conversation/${this.state.chatId}/message?user_message=${lastChat.message}`,
      { withCredentials: true }
    );
    sse.onmessage = (e) => this.getRealtimeData(JSON.parse(e.data));

    sse.onerror = (error) => {
      this.setState({ ...this.state, responseError: error });
      sse.close();
    };
  };

  // TODO: Need to fix this
  scrollToBottom = () => {
    this.messagesEnd?.scrollIntoView({ behavior: "smooth" });
  };

  onPDFLoadSuccess = ({ numPages }) => {
    this.setState({ totalPages: numPages });
  };

  onPageChange = (event, value) => {
    this.setState({ currentPage: value });
  };

  onQueryChange = (event) => {
    this.setState({ ...this.state, query: event.target.value });
  };

  getDivWidth = () => {
    return this.pdfWrapper.current != null
      ? this.pdfWrapper.current.getBoundingClientRect().width
      : "600";
  };

  getRealtimeData = (data) => {
    const lastConvo =
      this.state.conversation[this.state.conversation.length - 1];
    let page_numbers = [];

    if (data.status === "SUCCESS") {
      const arr = data.sub_processes.map((el) => el.status);

      if (arr.every((val) => val === arr[0]) && arr[0] === "FINISHED") {
        data.sub_processes.map((el) => {
          if (el.source === "sub_question") {
            el.metadata_map.sub_question.citations.map((c) =>
              page_numbers.push({
                document_id: c.document_id,
                page_number: c.page_number,
              })
            );
          }
        });
      }
    }

    if (lastConvo.type === "user") {
      this.setState({
        ...this.state,
        responseLoader: false,
        conversation: [
          ...this.state.conversation,
          {
            type: "ai",
            message: data.content,
            time: dayjs(),
            page_numbers: page_numbers,
          },
        ],
      });
    } else {
      let newConversation = [...this.state.conversation];
      newConversation[this.state.conversation.length - 1].message =
        data.content;
      newConversation[this.state.conversation.length - 1].page_numbers =
        newConversation[this.state.conversation.length - 1].page_numbers.concat(
          page_numbers
        );
      this.setState({
        ...this.state,
        responseLoader: false,
        conversation: [...newConversation],
      });
    }

    this.scrollToBottom();
  };

  addUserMessage = () => {
    this.setState({
      ...this.state,
      conversation: [
        ...this.state.conversation,
        {
          type: "user",
          message: this.state.query,
          time: dayjs(),
          page_numbers: [],
        },
      ],
      query: "",
      responseLoader: true,
    });
  };

  onKeyDownHandler = (e) => {
    if (
      e.keyCode === 13 &&
      !this.state.responseLoader &&
      this.state.query.trim().length > 0
    ) {
      this.addUserMessage();
    }
  };

  onChatSendBtnClick = (e) => {
    if (!this.state.responseLoader && this.state.query.trim().length > 0) {
      this.addUserMessage();
    }
  };

  handleDocumentClick = async (id) => {
    this.setState({ selectedDocument: id, currentPage: 1 });
  };

  truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  renderPDF = () => {
    const document = this.state.document_ids.find(
      (el) => el.id === this.state.selectedDocument
    );

    return (
      <Box>
        <div id={"pdfWrapper"} style={{ width: "100%" }} ref={this.pdfWrapper}>
          <Document
            onLoadSuccess={this.onPDFLoadSuccess}
            file={`data:application/pdf;base64,${document?.pdf}`}
          >
            <Page pageNumber={this.state.currentPage} />
          </Document>
        </div>
      </Box>
    );
  };

  renderPageNumber = (pageInfo) => {
    const document = this.state.document_ids.find(
      (doc) => doc.document_id === pageInfo.document_id
    );

    return (
      <p style={{ margin: 0 }}>
        <small>
          <a
            className="page-link"
            onClick={() =>
              this.setState({
                selectedDocument: document.id,
                currentPage: pageInfo.page_number,
              })
            }
          >
            {this.truncateString(document.title, 50)} - Page{" "}
            {pageInfo.page_number}
          </a>
        </small>
      </p>
    );
  };

  renderUserMessage = (chat) => {
    const pages = chat.page_numbers
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.document_id === value.document_id &&
              t.page_number === value.page_number
          )
      )
      .sort(function (a, b) {
        return a.document_id.localeCompare(b.document_id);
      });

    return (
      <div
        className={`chat-message-body ${
          chat.type == "user" ? "user-chat" : "ai-chat"
        }`}
      >
        <div className="chat-time">{chat.time.format("hh:mm A")}</div>
        <div
          className={
            chat.type == "user" ? "user-chat-message" : "ai-chat-message"
          }
        >
          {chat.message}

          {pages.length > 0 && (
            <div className="helper-text">
              <p style={{ margin: 0 }}>
                <small>Answer is taken from:</small>
              </p>
              {pages.map((pageInfo) => this.renderPageNumber(pageInfo))}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <Paper className="card">
        {this.renderTopBar()}
        <div className="card__body">
          <div className="card__body-inner padding-0">
            {this.state.documentLoader || this.state.chatLoader ? (
              <Box className="flex-center chat-container-loader-box">
                <CircularProgress />
              </Box>
            ) : (
              <Grid container>
                <Grid item xs={5} className="chat-container">
                  <div className="chat-body">
                    {this.state.conversation.map((chat) =>
                      this.renderUserMessage(chat)
                    )}

                    {this.state.responseLoader && (
                      <CircularProgress className="small-loader" />
                    )}

                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={(el) => {
                        this.messagesEnd = el;
                      }}
                    ></div>
                  </div>
                  <div className="chat-footer">
                    <TextField
                      multiline
                      fullWidth
                      rows={4}
                      placeholder="Write your query here..."
                      onChange={this.onQueryChange}
                      onKeyDown={this.onKeyDownHandler}
                      value={this.state.query}
                      disabled={this.state.responseLoader}
                      helperText="Press enter(↵) to send chat"
                    />

                    <div className="text-right">
                      <div
                        className="button is--primary"
                        onClick={this.onChatSendBtnClick}
                      >
                        Send
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <Grid container>
                    <Grid item xs={9}>
                      {this.renderPDF()}
                      <Pagination
                        count={this.state.totalPages}
                        onChange={this.onPageChange}
                        page={this.state.currentPage}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <List component="nav" aria-label="main mailbox folders">
                        {this.state.document_ids.map((item) => {
                          return (
                            <ListItemButton
                              key={item.id}
                              selected={this.state.selectedDocument === item.id}
                              onClick={() => this.handleDocumentClick(item.id)}
                              title={item.title}
                            >
                              <ListItemText
                                primary={this.truncateString(item.title, 30)}
                              />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </Paper>
    );
  }
}
