import {
    Modal,
    Paper,
    Stack,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    CircularProgress
} from "@mui/material";
import React from "react";

import GridComponent from "../SuperClasses/GridComponent";
import SearchResultGrid from "./SearchResultGrid";
import { FilterModal } from "../InCardWidgets/FilterModal";
import _ from "lodash";

export default class Search extends GridComponent {
    constructor(props) {
        super(props);
        let today = new Date();
	let backdate = new Date(today.getFullYear(), today.getMonth() - 2, today.getDate());
        today = String(today.getDate()).padStart(2, '0') + "/" + String(today.getMonth() + 1).padStart(2, '0') + "/" + String(today.getFullYear())
	backdate = String(backdate.getDate()).padStart(2, '0') + "/" + String(backdate.getMonth() + 1).padStart(2, '0') + "/" + String(backdate.getFullYear())
        this.today = today
        this.backdate = backdate
        this.state = {
            ...props, currentFilterTab: 0, showFilterModal: false,
            filters: {
                general: { date: { enabled: true, startDate: backdate, endDate: today } },
                monitoredSources: { enabled: true },
                bundestag: { enabled: false },
                twitter: { enabled: false },
                ep: { enabled: false },
                books: { enabled: false },
                news: { enabled: false, period: 0, maxArticles: 10 },
                orgs: { enabled: false },
                stakeholders: { enabled: false},
            },
            inputPlaceholder: 'Tags',
            showHint: true,
            languageFilter: 'en',
            keyword: '',
            suggestions: [],
            locationKeyword: '',
            locationSuggestions: [],
            locationKeys: [],
            orgSuggestions: [],
            orgKeyword: '',
            stakeSuggestions: [],
            stakeKeyword: '',
            isComponentDisabled: false,
	    AIcontext: '',
	    aiFillActive: false
        }
        this.displayStartDate = undefined;
        this.displayEndDate = undefined;
        this.displayNewsPeriod = "Last Hour"
        this.optionTab = 0;
        this.filtersActive = false;
        this.newsPeriodMap = new Map().set("Last Hour", 0).set("Last Day", 1).set("Last Week", 2).set("Last Month", 3).set("Last Year", 4).set("All time", 5)
        this.initComponent({ "type": "KeywordSearch", "title": "Knowledgebase Search" })
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState) {
        if (this.state.filters.monitoredSources !== prevState.filters.monitoredSources || this.state.filters.stakeholders !== prevState.filters.stakeholders || this.state.filters.orgs !== prevState.filters.orgs) {
            if (this.state.filters.monitoredSources.enabled === true || this.state.filters.stakeholders.enabled === true || this.state.filters.orgs.enabled === true) {
                this.setState({
                    inputPlaceholder: 'Tags',
                    showHint: true,
                    isComponentDisabled: false,
                });
            } else {
                this.setState({
                    inputPlaceholder: 'Search text',
                    showHint: false,
                    isComponentDisabled: true,
                });
            }
        }
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onClickGo();
        }
    }

    onClickGo = (event) => {
        if (this.state.keyword !== undefined || this.state.locationKeyword !== undefined || this.state.orgKeyword !== undefined || this.state.stakeKeyword !== undefined ) {
            if (this.state.filters.monitoredSources.enabled === false && this.state.filters.stakeholders.enabled === false && this.state.filters.orgs.enabled === false) {
                this.state.parentGrid.createNewComponent({
                    w: 6,
                    h: 6,
                    component: SearchResultGrid,
                    componentArgs: { "query": this.state.keyword, "filters": this.state.filters, "context": this.state.AIcontext }
                })
            } else {
                this.state.parentGrid.createNewComponent({
                    w: 6,
                    h: 6,
                    component: SearchResultGrid,
                    componentArgs: { "query": this.state.keyword, "locations": this.state.locationKeyword, "filters": this.state.filters, "language": this.state.languageFilter, "organisations": this.state.orgKeyword, "stakeholders": this.state.stakeKeyword, "context": this.state.AIcontext }
                })
            }    
        }
    }

    onAIFillClick = async (event) => {
        // Only proceed if AIcontext is not empty
        if (!this.state.AIcontext) return;

        try {
            this.setState({ aiFillActive: true });

            // Encode the context for URL
            const encodedContext = encodeURIComponent(this.state.AIcontext);
	    const language = this.state.languageFilter;
            
            // Fetch AI suggestions
            const response = await fetch(`https://jotup.co/api/llm_suggest?api_key=566439a8ac1443d0be4165e9c4034bb0&channel_id=2700072&context=${encodedContext}&language=${language}`);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
	    const data = JSON.parse(responseData.choices[0].message.content);

            // Update state with AI suggestions
            this.setState(prevState => ({
                keyword: data.Tags || prevState.keyword,
                locationKeyword: data.Locations || prevState.locationKeyword,
                stakeKeyword: data.People || prevState.stakeKeyword,
                orgKeyword: data.Organisations || prevState.orgKeyword,
		AIcontext: '',
                aiFillActive: false
            }));
        } catch (error) {
            console.error("Error fetching AI suggestions:", error);
            this.setState({ aiFillActive: false });
        }
    }

    onModalClose = () => {
        this.setState((prev) => ({ ...prev, showFilterModal: false }))
    }

    updateFilter = (filters) => {
        let backup_filters = {
            general: { date: { enabled: false, startDate: this.today, endDate: this.today } },
            monitoredSources: { enabled: true },
            bundestag: { enabled: false },
            twitter: { enabled: false },
            ep: { enabled: false },
            books: { enabled: false },
            news: { enabled: false, period: 0, maxArticles: 10 },
            orgs: { enabled: false },
            stakeholders: { enabled: false},
        }

        this.filtersActive = !_.isEqual(filters, backup_filters)
        this.setState(prevState =>
            ({ filters: filters }))
    }

    onFilterClick(event) {
        this.optionTab = 1
        this.setState((prev) => ({ ...prev, showFilterModal: true }))
    }

    onDateClick(event) {
        this.optionTab = 0
        this.setState((prev) => ({ ...prev, showFilterModal: true }))
    }

    fetchSuggestions = _.debounce(async (keyword) => {
        if (!keyword || /[,~+]/.test(keyword)) return this.setState({ suggestions: [] });
        const language = this.state.languageFilter;
        try {
            const response = await fetch(`https://jotup.co/jtsts?m=${encodeURIComponent(keyword)}&language=${language}&raw=1`);
            const data = await response.json();

            const reqSuggestion = data.req ? data.req : '';
            const optSuggestions = data.opt ? data.opt.split(',').map(opt => `~${opt.trim()}`).join(', ') : '';
            const strSuggestions = data.str ? data.str.split(',').map(str => `+${str.trim()}`).join(', ') : '';

            const suggestions = [reqSuggestion, optSuggestions, strSuggestions].filter(s => s).join(', ');

            this.setState({ suggestions: suggestions ? [suggestions] : [] });
        } catch (error) {
            console.error("Error fetching suggestions:", error);
            this.setState({ suggestions: [] });
        }
    }, 1300);

    handleInputChange = (event) => {
        const keyword = event.target.value;
        this.setState({ keyword }, () => {
            if (this.state.filters.monitoredSources.enabled === true) {
                this.fetchSuggestions(keyword);
            }    
        });
    };

    handleLocationInputChange = (event) => {
        const locationKeyword = event.target.value;
        this.setState({ locationKeyword }, () => {
            this.fetchLocationSuggestions(locationKeyword);
        });
    };

    handleOrgInputChange = (event) => {
        const orgKeyword = event.target.value;
        this.setState({ orgKeyword }, () => {
            this.fetchOrgSuggestions(orgKeyword);
        });
    };

    handleStakeInputChange = (event) => {
        const stakeKeyword = event.target.value;
        this.setState({ stakeKeyword }, () => {
            this.fetchStakeSuggestions(stakeKeyword);
        });
    };

    handleContextInputChange = (event) => {
        this.setState({ AIcontext: event.target.value });
    };

    fetchOrgSuggestions = async (orgKeyword) => {
        if (!orgKeyword) return this.setState({ orgSuggestions: [] });
        try {
            const response = await fetch(`https://jotup.co/api/polorgs/${encodeURIComponent(orgKeyword)}`);
            const data = await response.json();

            this.setState({ orgSuggestions: Object.keys(data) });
        } catch (error) {
            console.error("Error fetching suggestions:", error);
            this.setState({ orgSuggestions: [] });
        }
    };

    fetchLocationSuggestions = async (locationKeyword) => {
        if (!locationKeyword) return this.setState({ locationSuggestions: [] });
        try {
            const response = await fetch(`https://jotup.co/api/geoautocomplete/${encodeURIComponent(locationKeyword)}`);
            const data = await response.json();

            this.setState({ locationSuggestions: Object.keys(data) });
        } catch (error) {
            console.error("Error fetching suggestions:", error);
            this.setState({ locationSuggestions: [] });
        }
    };

    fetchStakeSuggestions = async (stakeKeyword) => {
        if (!stakeKeyword) return this.setState({ stakeSuggestions: [] });
        try {
            const response = await fetch(`https://jotup.co/api/people/${encodeURIComponent(stakeKeyword)}`);
            const data = await response.json();

            this.setState({ stakeSuggestions: Object.keys(data) });
        } catch (error) {
            console.error("Error fetching suggestions:", error);
            this.setState({ stakeSuggestions: [] });
        }
    };

    handleLanguageChange = (event) => {
        this.setState({ languageFilter: event.target.value }, () => {
            this.fetchSuggestions(this.state.inputValue);
        });
    };

    addKeyword = (tagText) => {
        this.setState(prevState => ({
            keyword: prevState.keyword ? `${prevState.keyword}, ${tagText}` : tagText
        }));
    }

	addLocation = (locationText) => {
		this.setState(prevState => ({
			locationKeyword: prevState.locationKeyword ? `${prevState.locationKeyword}, ${locationText}` : locationText
		}));
	}

    render() {
        const { inputPlaceholder, showHint, suggestions, keyword, locationKeyword, locationSuggestions, isComponentDisabled, orgKeyword, orgSuggestions, stakeSuggestions, stakeKeyword, AIcontext, aiFillActive } = this.state;
        return (
            <React.Fragment>
                <Modal open={this.state.showFilterModal}>
                    <div>
                        <FilterModal tab={this.optionTab} onClose={this.onModalClose} onFilterSet={this.updateFilter} filters={this.state.filters} />
                    </div>
                </Modal>
                <Paper className={"card"} elevation={1} sx={{ width: "100%", overflow: "auto", minHeight: 'min-content' }}>
                    {this.renderTopBar()}
                    <div className={"card__body"}>
                        <div className={"card__body-inner"}>
                            <Stack className={"search"} marginTop={0.5} direction={"row"} spacing={2}>
                                <FormControl className={"search__input"} style={{padding: '5px'}} disabled={isComponentDisabled}>
                                    <InputLabel>Language</InputLabel>
                                    <Select
                                        value={this.state.languageFilter}
                                        label="Language"
                                        onChange={this.handleLanguageChange}
                                    >
                                        <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="de">Deutsch</MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{width: '75%'}}>
                                    <Autocomplete className={"search__input"}
                                        freeSolo
                                        options={suggestions}
                                        inputValue={keyword}
                                        onInputChange={(event, newInputValue) => {
                                            this.handleInputChange({target: {value: newInputValue}});
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label={inputPlaceholder} variant="outlined" fullWidth />
                                        )}
                                        filterOptions={(options, state) => {
                                            return options;
                                        }}
                                    />
                                    {showHint ? (
                                        <Typography style={{color: 'grey', fontSize: '0.75rem', textAlign: 'center'}}>
                                            Separate terms with semicolons ';'. Prefix terms with: '~' for optional tags; '#' for metatags; '+' for exact text.
                                        </Typography>
                                    ) : (
                                        <div style={{ height: '18px' }}></div>
                                    )}
                                    <Autocomplete className={"search__input"}
                                        freeSolo
                                        disabled={isComponentDisabled}
                                        options={locationSuggestions}
                                        inputValue={locationKeyword}
                                        onInputChange={(event, newInputValue) => {
                                            this.handleLocationInputChange({target: {value: newInputValue}});
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Locations" variant="outlined" fullWidth />
                                        )}
                                        filterOptions={(options, state) => {
                                            return options;
                                        }}
                                    />
                                    <div style={{ height: '10px' }}></div>
                                    <Autocomplete className={"search__input"}
                                        freeSolo
                                        disabled={isComponentDisabled}
                                        options={orgSuggestions}
                                        inputValue={orgKeyword}
                                        onInputChange={(event, newInputValue) => {
                                            this.handleOrgInputChange({target: {value: newInputValue}});
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Organisations" variant="outlined" fullWidth />
                                        )}
                                        filterOptions={(options, state) => {
                                            return options;
                                        }}
                                    />
                                    <div style={{ height: '10px' }}></div>
                                    <Autocomplete className={"search__input"}
                                        freeSolo
                                        disabled={isComponentDisabled}
                                        options={stakeSuggestions}
                                        inputValue={stakeKeyword}
                                        onInputChange={(event, newInputValue) => {
                                            this.handleStakeInputChange({target: {value: newInputValue}});
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Stakeholders" variant="outlined" fullWidth />
                                        )}
                                        filterOptions={(options, state) => {
                                            return options;
                                        }}
                                    />
                                    <div style={{ height: '10px' }}></div>
                                    <TextField className={"search__input"}
                                        disabled={isComponentDisabled}
                                        label="Context"
					variant="outlined" fullWidth 
				        value={AIcontext}
                                        onChange={(event) => {
                                            this.handleContextInputChange(event);
                                        }}
                                    />
                                </div>
                                <Stack direction="column" spacing={2}>
                                    <div className={"button is--primary"} variant={"contained"} color={"primary"} onClick={this.onClickGo}>Search</div>
                                    <div className={"search__filter button is--secondary w-embed " + (this.filtersActive ? "is--active" : "")} onClick={() => (this.onFilterClick())}>
                                        <svg className={"button__icon"} width="16" height="16" viewBox="0 0 20 20" fill="none"
                                            xmlns="https://www.w3.org/2000/svg">
                                            <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
                                                strokeWidth="1.66667" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                        </svg>
                                        Filter
                                    </div>
					<div className={"ai-fill button is--secondary w-embed " + (this.aiFillActive ? "is--active" : "")} onClick={() => (this.onAIFillClick())}>
					        {aiFillActive ? (
								<CircularProgress 
								size={16} 
								thickness={4} 
								className="button__icon"
								style={{ color: '#344054' }}
								/>
								) : (
									<svg 
									className="button__icon" 
									width="16" 
									height="16" 
									viewBox="0 0 24 24" 
									fill="none" 
									xmlns="http://www.w3.org/2000/svg"
									>
									<path d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" 
									stroke="#344054" 
									strokeWidth="1.5" 
									strokeLinecap="round" 
									strokeLinejoin="round"
									/>
									</svg>
								    )}
					<span>AI Fill</span>
				   </div>
                                </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                            </Stack>
                        </div>
                    </div>
                </Paper>
            </React.Fragment>
        );
    }
}
