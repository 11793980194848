import GridComponent from "../SuperClasses/GridComponent";
//import {Box, CircularProgress, Divider, IconButton, Paper, Stack} from "@mui/material";
import {Box, CircularProgress, Divider, Paper, Stack} from "@mui/material";
import React from "react";
import TimeSeriesChart from "../InCardWidgets/TimeChart";
//import {BASE_URL, DEFAULT_FETCH_HEADERS} from "../config";
import PDataGrid from "../InCardWidgets/PDataGrid";

export default class VisualDataGrid extends GridComponent
{
    constructor(props) {
        super(props);
        this.initComponent({"type": "StakeholderActivity", "title": "Stakeholder Activity"})
    }

    componentDidMount() {

    }

    onMarkerClickHandler = (event, context, props, date) =>
    {
        if(this.state.timestamp !== undefined && event["timeStamp"] === this.state.timestamp)
        {
            return
        }
        if(this.state.filterData !== undefined)
        {
            if (date === this.state.filterData.dataPoint)
            {
                this.setState({"filterData":undefined,"timestamp":event["timeStamp"]})
            }
            else
            {
                this.setState({"filterData":{"series":props["seriesIndex"],"dataPoint":date},"timestamp":event["timeStamp"]})
            }
        }
        else
        {
            this.setState({"filterData":{"series":props["seriesIndex"],"dataPoint":date},"timestamp":event["timeStamp"]})
        }
    }

    renderActivityCharts = () =>
    {
        let srs = []
        this.props.channels.forEach(e =>
        {
            let act = e[Object.keys(e)[0]]["resampled_activity"]
            let dates = Object.keys(act).map(d => new Date(d / 1000/1000).toDateString());
            let docs = Object.values(act);
            var c = dates.map(function(x, i) {
                return [x, docs[i]];
            });
            srs.push({"name":Object.keys(e)[0].charAt(0).toUpperCase() + Object.keys(e)[0].slice(1), "data":c})
        })
        return(<TimeSeriesChart series={srs} onMarkerClickHandler={this.onMarkerClickHandler}/>)
    }

    renderDataGrid = () =>
    {
        let rows = []
        let columns= []
        this.props.channels.forEach((e,idx) =>
        {
            e[Object.keys(e)[0]]["documents"].forEach(x =>
            {

                if (this.state.filterData !== undefined)
                {
                    let filterDate = new Date(Date.parse(this.state.filterData.dataPoint));
                    let currentDate = new Date(x["Date"]);
                    if(idx !== this.state.filterData.series)
                    {
                        return;
                    }
                    if(filterDate.getFullYear() !== currentDate.getFullYear() || filterDate.getMonth() !== currentDate.getMonth())
                    {
                        return
                    }
                }
                rows.push(x)
            })
        });
        ["id", "Type", "Title", "Date"].forEach(e =>
        {
            columns.push({field:e,
                headerName:e,
                accessor: 'accessor',
                editable:false,
                hide: e.toLowerCase().includes("id"),
                flex:1})
        })
        return <PDataGrid columns={columns} rows={rows} parent={this}/>
    }

    render() {
        if (this.props.channels === undefined)
        {
            return (
                <Paper  elevation={0} sx={{width: "100%", height: "100%"}} style={{overflow: "hidden", userSelect: "none"}}>
                    <Stack height={"100%"} overflow={"hidden"}>
                        {this.renderTopBar()}
                        <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress/></Box>
                    </Stack>
                </Paper>)
        }
        else {
            return (
                <Paper className={"card"} key={this.state.parentKey} elevation={0} sx={{width: "100%", height: "100%"}}
                       style={{overflow: "hidden", userSelect: "none"}}>
                    <Stack height={"100%"}>
                        {this.renderTopBar()}
                        <Divider/>
                        {this.renderActivityCharts()}
                        <Divider/>
                        {this.renderDataGrid()}
                    </Stack>
                </Paper>
            )
        }
    }
}
